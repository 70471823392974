export class User {

  public id: number;
  public role: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public active: boolean;

  constructor(id: number, role: string, first_name: string, last_name: string, email: string = '', active: boolean = true) {
    this.id = id;
    this.role = role;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.active = active;
  }

  getName(): string {
    return this.first_name;
  }
  getSurname(): string {
    return this.last_name;
  }
  getRole(): string {
    return this.role;
  }
}
