
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

//Angular
import { Router } from '@angular/router';

//Constants
import { ROUTES } from 'src/app/utils/constants';


@Component({
  selector: 'app-userarea-controls',
  templateUrl: './userarea-controls.component.html',
  styleUrls: ['./userarea-controls.component.scss']
})

export class UserAreaControlsComponent implements OnInit {
  ROUTES = ROUTES;
  isOpen = false;
  route: string;

  constructor(
    private router: Router,
  ) {
    this.route = this.router.url.replace('/', '');
  }

  ngOnInit(): void { }

  onClickDownloadsFiles(){
    const link1 = document.createElement("a");
    // link.setAttribute('type', 'hidden');
    link1.href = 'assets/resources/documents-massive-imports/HCpro Guida alla compilazione delle richieste.pdf';
    link1.download = "HCpro Guida alla compilazione delle richieste.pdf"
    document.body.appendChild(link1);
    link1.click();
    link1.remove();

    const link2 = document.createElement("a");
    // link.setAttribute('type', 'hidden');
    link2.href = 'assets/resources/documents-massive-imports/HCpro Template.xlsx';
    link2.download = "HCpro Template.xlsx"
    document.body.appendChild(link2);
    link2.click();
    link2.remove();
  }
}
