
export class Service {
  public id: number;
  public name: string;
  public credits: number;
  public enabled: boolean;
  public logo_filename: string;

  constructor (id: number, name: string, credits: number, enabled: boolean, logo_filename: string) {
    this.id = id;
    this.name = name;
    this.credits = credits;
    this.enabled = enabled;
    this.logo_filename = logo_filename;
  }
}
