import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  logout,
  storeToken,
  loginSuccess,
  idleTimeout,
} from './authentication.actions';

import { AuthenticationService } from '../../services/auth/authentication.service';
import { User } from 'src/app/models/User';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  storeToken$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(storeToken),
      map((action) => action.token),
      exhaustMap(() =>
        this.authService.verifyUser().pipe(
          map((user) => loginSuccess({user: new User(
            user.data.uuid,
            user.data.role,
            user.data.first_name,
            user.data.last_name,
          )})),
          catchError((error) => of(logout()))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        map((action) => {})
      ),
    { dispatch: false }
  );

  removeLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() =>localStorage.removeItem("state"))
      ),
    { dispatch: false }
  );

  logoutIdleUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(idleTimeout),
      map(() => logout())
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
  ) {}
}
