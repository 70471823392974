import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CategoryStatusRequest, PaginationParams, SummaryRequest } from 'src/app/models/global.request';
import Formatter from 'src/app/utils/formatters';

@Injectable({
  providedIn: 'root'
})
export class AdminRequestsService {

  formatter!: Formatter;

  constructor(
    private http: HttpClient
  ) {
    this.formatter = new Formatter();
  }

  public getRequestsList(params: PaginationParams): Observable<any> {
    const p = this.formatter.formatRequestPagination(params);
    return this.http.get<any[]>(`${environment.apiUrl}admin/requests`, {params: p});
  }

  public getRequest(id: number): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}admin/requests/${id}`);
  }

  public setProperty(id: number, property: string, params: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/set${property}`, params);
  }

  public setResearchHit(id: number): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/research-hits`, {});
  }

  public setSummary(id: number, params: SummaryRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/summary`, params);
  }

  public getCategoryStatuses(id: number, target: string, id_target: number): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}admin/requests/${id}/category-statuses/${target}/${id_target}`);
  }

  public setCategoryStatus(id: number, params: CategoryStatusRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/setCategoryStatus`, params);
  }

  public getResearchHits(id: number, target: string, id_target: number): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}admin/requests/${id}/research-hits/${target}/${id_target}`);
  }

  public getResearchHitAttachment(id_hit: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}admin/get-attachment/${id_hit}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*"
      },
      observe: "response",
      responseType: "blob",
    });
  }

  public saveResearchHit(id: number, formData: FormData): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/research-hits`, formData);
  }

  public updateResearchHit(id: number, id_hit: number, formData: FormData): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}admin/requests/${id}/research-hits/${id_hit}`, formData);
  }

  public deleteResearchHit(id: number, id_hit: number): Observable<any> {
    return this.http.delete<any[]>(`${environment.apiUrl}admin/requests/${id}/research-hits/${id_hit}`);
  }

  public generateReport(id: number, target: string, id_target: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}admin/requests/${id}/generateReport/${target}/${id_target}`, null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      observe: "response",
      responseType: "blob",
    });
  }

}
