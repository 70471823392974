import { Company } from "../models/Company";
import { Notification } from "../models/Notification";
import { Report } from "../models/Report";
import { ResearchCategory } from "../models/ResearchCategory";
import { ResearchHit } from "../models/ResearchHit";
import { Service } from "../models/Service";
import { User } from "../models/User";
import { PaginationParams } from "../models/global.request";

export default class Formatter {
  formatGetFirstChar(string: string){
    return string.split(' ').map(n => n[0]).join('');
  }

  formatServiceFormControlName(string: string) {
    return string.replaceAll(' ', '').toLowerCase();
  }

  formatResponseGetResearchCategories(data: any) {
    const categoriesList = [];
    for (let index = 0; index < data.length; index++) {
      categoriesList.push(
        new ResearchCategory(
          data[index].id,
          data[index].key,
          data[index].label,
          data[index].visible,
        )
      )
    }
    return categoriesList;
  }

  formatResponseGetServices(data: any) {
    const servicesList = [];
    for (let index = 0; index < data.length; index++) {
      servicesList.push(
        new Service(
          data[index].id,
          data[index].name,
          data[index].credits,
          data[index].enabled,
          data[index].logo_filename,
        )
      )
    }
    return servicesList;
  }

  formatResponseGetUserRequests(data: any) {
    const reports: Report[] = [];

    for (let index = 0; index < data.list.length; index++) {
      reports.push(
        new Report(
          data.list[index].id,
          data.list[index].status,
          data.list[index].priority,
          data.list[index].created_at,
          data.list[index].download_date,
          data.list[index].expiry_date,
          data.list[index].completed_at,
          data.list[index].operator_id,
          data.list[index].service_id,
          data.list[index].updated_at,
          data.list[index].user_id,
          data.list[index].persons,
          data.list[index].company,
          data.list[index].service
        )
      )
    }

    return reports;
  }

  formatResponseGetAdminRequests(data: any) {
    const reports: Report[] = [];

    for (let index = 0; index < data.list.length; index++) {
      reports.push(
        new Report(
          data.list[index].id,
          data.list[index].status,
          data.list[index].priority,
          data.list[index].created_at,
          data.list[index].download_date,
          data.list[index].expiry_date,
          data.list[index].completed_at,
          data.list[index].operator_id,
          data.list[index].service_id,
          data.list[index].updated_at,
          data.list[index].user_id,
          data.list[index].persons,
          data.list[index].company,
          data.list[index].service,
          data.list[index].customer,
          data.list[index].operator
        )
      )
    }

    return reports;
  }

  formatResponseGetAdminRequest(data: any) {
    let company = data.company ? new Company(
      data.company.id,
      data.company.business_name,
      data.company.legal_form,
      data.company.alias,
      data.company.vat_number,
      data.company.head_office,
      data.company.country,
      data.company.request_id,
      data.company.sector,
      data.company.summary,
      data.company.summary_links,
      data.company.summary_keywords,
    ) : null;
    return new Report(
      data.id,
      data.status,
      data.priority,
      data.created_at,
      data.download_date,
      data.expiry_date,
      data.completed_at,
      data.operator_id,
      data.service_id,
      data.updated_at,
      data.user_id,
      data.persons,
      company,
      data.service,
      data.customer,
      data.operator
    );
  }

  formatResponseGetOperators(data: any) {
    const operators: User[] = [];

    for (let index = 0; index < data.length; index++) {
      operators.push(
        new User(
          data[index].id,
          'operator',
          data[index].name,
          data[index].surname,
          data[index].user_login,
          true
        )
      )
    }

    return operators;
  }

  formatGetResearchHits(data: any) {
    const researchHits: ResearchHit[] = [];

    for (let index = 0; index < data.length; index++) {
      researchHits.push(
        new ResearchHit(
          data[index].id,
          data[index].person_id,
          data[index].title,
          data[index].url,
          data[index].notes,
          data[index].type,
          data[index].severity,
          data[index].research_hit_categories.map((c: any) => c.research_category_id),
          data[index].research_hit_attachments
        )
      )
    }

    return researchHits;
  }

  formatResponseResearchHit(data: any) {
    return new ResearchHit(
      data.id,
      data.person_id,
      data.title,
      data.url,
      data.notes,
      data.type,
      data.severity,
      data.research_hit_categories.map((c: any) => c.research_category_id),
      data.research_hit_attachments
    );
  }

  formatMIMEType(filename: string, type: string) {
    const extension = filename.split('.').pop();
    switch (type) {
      case 'image':
        return type + '/' + extension;
      case 'pdf':
        return 'application/' + extension;
      default:
        return type;
    }
  }

  formatRequestPagination(params: PaginationParams) {
    var p;
    var _p: { orderby?: string; orderdir?: string; start: number; limit: number; };
    var __p = {
      start: params.start,
      limit: params.limit,
    };

    if (params.orderby && params.orderdir) {
      _p = {...__p, orderby: params.orderby, orderdir: params.orderdir};
    } else {
      _p = __p;
    }

    if (params.filters.length > 0) {
      params.filters.forEach((filter) => {
        _p = {..._p, [filter.key]: filter.value};
      });
    }

    p = _p;

    return p;
  }

  formatNotifications(data: any) {
    const notifications: Notification[] = [];

    for (let index = 0; index < data.list.length; index++) {
      notifications.push(
        new Notification(
          data.list[index].id,
          data.list[index].type,
          data.list[index].target,
          data.list[index].is_read,
          data.list[index].created_at,
          JSON.parse(data.list[index].extra),
          data.list[index].request_id,
          data.list[index].request_id ? new Report(
            data.list[index].request.id,
            data.list[index].request.status,
            data.list[index].request.priority,
            data.list[index].request.created_at,
            data.list[index].request.download_date,
            data.list[index].request.expiry_date,
            data.list[index].request.completed_at,
            data.list[index].request.operator_id,
            data.list[index].request.service_id,
            data.list[index].request.updated_at,
            data.list[index].request.user_id,
            data.list[index].request.persons,
            data.list[index].request.company,
            data.list[index].request.service,
            data.list[index].request.customer,
            data.list[index].request.operator
          ) : null
        )
      )
    }

    return notifications;
  }
}
