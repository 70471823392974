<ng-container *ngIf="(authState$ | async); let authState;">
  <header id="header" *ngIf="(authState?.isAuthenticated)" >
    <div class="navbar container">
      <a class="logo-wrapper"  [routerLink]="getRouteByRole()">
        <div class="logo">
          <ng-container *ngIf="authState?.user?.role ==='customer'; else adminLogo">
            <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M61.1406 26.8019L47.6397 23.3451V0H44.1082V3.54331H35.4627L31.9312 7.08662H44.1082V10.6299H28.3997L24.8682 14.1732H44.1082V17.6888L29.7883 29.1188L18.7535 20.3084L14.4554 16.8794L11.943 19.4002L16.2411 22.8292L16.549 23.0763H8.27914L4.74765 26.6196H20.9888L24.7327 29.61H1.76728L0 31.3832L1.76728 33.1564H24.7327L20.9888 36.1467H4.74457L8.27606 39.69H16.546L16.2381 39.9372L11.9399 43.3662H11.9369L14.1352 46.1434L14.4554 45.8901L18.7535 42.461L29.7883 33.6506L44.1082 45.0807V48.5962H24.8682L28.3997 52.1395H44.1082V55.6828H31.9312L35.4627 59.2261H44.1082V62.7694H47.6397V39.4985L57.6091 36.9901V39.829H61.1406V26.7988V26.8019ZM44.1082 40.5488L32.627 31.3832L44.1082 22.2175V40.5488ZM57.6091 33.3386L47.6397 35.8471V27.0027L57.6091 29.5544V33.3386V33.3386Z" fill="#2B2F3B"/>
              <path d="M55.0259 33.107C55.939 33.107 56.6793 32.3642 56.6793 31.4481C56.6793 30.5319 55.939 29.7892 55.0259 29.7892C54.1128 29.7892 53.3726 30.5319 53.3726 31.4481C53.3726 32.3642 54.1128 33.107 55.0259 33.107Z" fill="#2B2F3B"/>
            </svg>
          </ng-container>
          <ng-template #adminLogo>
            <svg width="75" height="77" viewBox="0 0 75 77" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1375_4735)">
                <path d="M75 32.7677L58.4387 28.5414V0H54.1067V4.33201H43.5014L39.1694 8.66402H54.1067V12.996H34.8373L30.5053 17.328H54.1067V21.626L36.5407 35.6003L23.0046 24.8288L17.7321 20.6365L14.6503 23.7184L19.9227 27.9107L20.3004 28.2128H10.1559L5.82385 32.5448H25.7465L30.3392 36.2008H2.16789L0 38.3687L2.16789 40.5366H30.3392L25.7465 44.1925H5.82007L10.1521 48.5245H20.2966L19.9189 48.8267L14.6465 53.0189H14.6427L17.3394 56.4143L17.7321 56.1046L23.0046 51.9123L36.5407 41.1409L54.1067 55.1151V59.4131H30.5053L34.8373 63.7451H54.1067V68.0771H39.1694L43.5014 72.4091H54.1067V76.7411H58.4387V48.2904L70.668 45.2236V48.6945H75V32.7639V32.7677ZM54.1067 49.5745L40.0229 38.3687L54.1067 27.1629V49.5745ZM70.668 40.7594L58.4387 43.8262V33.0131L70.668 36.1328V40.7594V40.7594Z" fill="#D9D9D9"/>
                <path d="M67.4992 40.4761C68.6193 40.4761 69.5274 39.5681 69.5274 38.4479C69.5274 37.3278 68.6193 36.4198 67.4992 36.4198C66.3791 36.4198 65.4711 37.3278 65.4711 38.4479C65.4711 39.5681 66.3791 40.4761 67.4992 40.4761Z" fill="#D9D9D9"/>
              </g>
              <defs>
                <clipPath id="clip0_1375_4735">
                <rect width="75" height="76.7373" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </ng-template>
        </div>
        <div class="title" *ngIf="(authState?.user?.role ==='administrator' || authState?.user?.role ==='operator')" >
          <span>Screening</span>
        </div>
      </a>
      <div class="profile-wrapper"  *ngIf="(authState?.user?.role ==='administrator' || authState?.user?.role ==='operator')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.98132 18.6938C6.54561 17.5824 7.40666 16.6488 8.46902 15.9968C9.53138 15.3447 10.7536 14.9995 12.0001 14.9995C13.2466 14.9995 14.4688 15.3447 15.5311 15.9968C16.5935 16.6488 17.4545 17.5824 18.0188 18.6938" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="current-user">{{formatter.formatGetFirstChar(authState?.user?.first_name)}}. {{authState?.user?.last_name}}</div>
      </div>
      <div class="actions-wrapper">
        <div class="language-selector">
          <div class="lang" (click)="translateLanguageTo('it')" [ngClass]="isCurrentLanguage('it') ? 'current' : ''">IT</div>
          <div class="lang" (click)="translateLanguageTo('en')" [ngClass]="isCurrentLanguage('en') ? 'current' : ''">EN</div>
        </div>
        <div (click)="toggle()">
          <ng-container *ngIf="(notificationsState$ | async); let notifState;">
            <ng-container *ngIf="notifState.unreadCount > 0; else noUnread">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.39062 11.125C4.38958 10.384 4.53514 9.65017 4.81892 8.96572C5.1027 8.28126 5.5191 7.65971 6.04412 7.13686C6.56914 6.61402 7.19242 6.20022 7.87805 5.91928C8.56368 5.63835 9.29812 5.49584 10.0391 5.49996C13.1328 5.5234 15.6094 8.09371 15.6094 11.1953V11.75C15.6094 14.5468 16.1953 16.1718 16.7109 17.0625C16.7657 17.1573 16.7946 17.2649 16.7947 17.3744C16.7948 17.4839 16.7661 17.5916 16.7115 17.6865C16.6569 17.7815 16.5783 17.8604 16.4836 17.9154C16.3889 17.9704 16.2814 17.9996 16.1719 18H3.82812C3.7186 17.9996 3.6111 17.9704 3.51639 17.9154C3.42169 17.8604 3.3431 17.7815 3.28851 17.6865C3.23391 17.5916 3.20523 17.4839 3.20532 17.3744C3.20542 17.2649 3.2343 17.1573 3.28906 17.0625C3.80468 16.1718 4.39062 14.5468 4.39062 11.75V11.125Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 18V18.625C7.5 19.288 7.76339 19.9239 8.23223 20.3928C8.70107 20.8616 9.33696 21.125 10 21.125C10.663 21.125 11.2989 20.8616 11.7678 20.3928C12.2366 19.9239 12.5 19.288 12.5 18.625V18" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <circle cx="20" cy="3" r="3" fill="#C90000"/>
              </svg>
            </ng-container>
            <ng-template #noUnread>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.39062 8.12496C4.38958 7.38401 4.53514 6.65017 4.81892 5.96572C5.1027 5.28126 5.5191 4.65971 6.04412 4.13686C6.56914 3.61402 7.19242 3.20022 7.87805 2.91928C8.56368 2.63835 9.29812 2.49584 10.0391 2.49996C13.1328 2.5234 15.6094 5.09371 15.6094 8.19528V8.74996C15.6094 11.5468 16.1953 13.1718 16.7109 14.0625C16.7657 14.1573 16.7946 14.2649 16.7947 14.3744C16.7948 14.4839 16.7661 14.5916 16.7115 14.6865C16.6569 14.7815 16.5783 14.8604 16.4836 14.9154C16.3889 14.9704 16.2814 14.9996 16.1719 15H3.82812C3.7186 14.9996 3.6111 14.9704 3.51639 14.9154C3.42169 14.8604 3.3431 14.7815 3.28851 14.6865C3.23391 14.5916 3.20523 14.4839 3.20532 14.3744C3.20542 14.2649 3.2343 14.1573 3.28906 14.0625C3.80468 13.1718 4.39062 11.5468 4.39062 8.74996V8.12496Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 15V15.625C7.5 16.288 7.76339 16.9239 8.23223 17.3928C8.70107 17.8616 9.33696 18.125 10 18.125C10.663 18.125 11.2989 17.8616 11.7678 17.3928C12.2366 16.9239 12.5 16.288 12.5 15.625V15" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </ng-template>
          </ng-container>
        </div>
        <a href="{{env.commerceUrl}}my-account/" target="_blank" *ngIf="(authState?.user?.role ==='customer')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.98132 18.6938C6.54561 17.5824 7.40666 16.6488 8.46902 15.9968C9.53138 15.3447 10.7536 14.9995 12.0001 14.9995C13.2466 14.9995 14.4688 15.3447 15.5311 15.9968C16.5935 16.6488 17.4545 17.5824 18.0188 18.6938" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <button class="base--button logout" (click)="onClickLogout()">{{ 'logout' | translate }}</button>
      </div>
    </div>

  </header>

  <header id="header" *ngIf="!(authState?.isAuthenticated)">
    <div class="navbar container">
      <a class="logo-wrapper">
        <div class="logo">
          <svg width="75" height="77" viewBox="0 0 75 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1375_4735)">
              <path d="M75 32.7677L58.4387 28.5414V0H54.1067V4.33201H43.5014L39.1694 8.66402H54.1067V12.996H34.8373L30.5053 17.328H54.1067V21.626L36.5407 35.6003L23.0046 24.8288L17.7321 20.6365L14.6503 23.7184L19.9227 27.9107L20.3004 28.2128H10.1559L5.82385 32.5448H25.7465L30.3392 36.2008H2.16789L0 38.3687L2.16789 40.5366H30.3392L25.7465 44.1925H5.82007L10.1521 48.5245H20.2966L19.9189 48.8267L14.6465 53.0189H14.6427L17.3394 56.4143L17.7321 56.1046L23.0046 51.9123L36.5407 41.1409L54.1067 55.1151V59.4131H30.5053L34.8373 63.7451H54.1067V68.0771H39.1694L43.5014 72.4091H54.1067V76.7411H58.4387V48.2904L70.668 45.2236V48.6945H75V32.7639V32.7677ZM54.1067 49.5745L40.0229 38.3687L54.1067 27.1629V49.5745ZM70.668 40.7594L58.4387 43.8262V33.0131L70.668 36.1328V40.7594V40.7594Z" fill="#D9D9D9"/>
              <path d="M67.4992 40.4761C68.6193 40.4761 69.5274 39.5681 69.5274 38.4479C69.5274 37.3278 68.6193 36.4198 67.4992 36.4198C66.3791 36.4198 65.4711 37.3278 65.4711 38.4479C65.4711 39.5681 66.3791 40.4761 67.4992 40.4761Z" fill="#D9D9D9"/>
            </g>
            <defs>
              <clipPath id="clip0_1375_4735">
              <rect width="75" height="76.7373" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="title" *ngIf="(authState?.user?.role ==='administrator')" >
          <span>Screening</span>
        </div>
      </a>
    </div>
  </header>
</ng-container>
