export class Company {
  public id: number | null;
  public business_name: string;
  public legal_form: string;
  public alias: string;
  public vat_number: string;
  public head_office: string;
  public country: string;
  public request_id: number | null;
  public sector: string | null;
  public summary: string | null;
  public summary_links: string | string[] | null;
  public summary_keywords: string | null;

  constructor (id: number | null, business_name: string, legal_form: string, alias: string, vat_number: string, head_office: string, country: string, request_id: number | null = null, sector: string | null = null, summary: string | null = null, summary_links: string | string[] | null = null, summary_keywords: string | null = null) {
    this.id = id;
    this.business_name = business_name;
    this.legal_form = legal_form;
    this.alias = alias;
    this.vat_number = vat_number;
    this.head_office = head_office;
    this.country = country;
    this.request_id = request_id;
    this.sector = sector;
    this.summary = summary;
    this.summary_links = typeof summary_links === 'string' && summary_links?.split(', ') ? summary_links?.split(', ') : summary_links;
    this.summary_keywords = summary_keywords;
  }
}
