//Angular
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { logout } from "src/app/stores/auth/authentication.actions";
import { AuthState } from "src/app/stores/auth/authentication.reducer";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],

})
export class LogoutComponent {

  //Constructor
  constructor(
    private router: Router,
    private store: Store<{ authState: AuthState }>
  ) {
    this.store.dispatch(logout());
    window.top?.close();
  }
}

