import { User} from '../../models/User';
import { Action, createReducer, on } from '@ngrx/store';
import {
  logout,
  loginSuccess,
  storeToken,
} from './authentication.actions';


export const initialState: AuthState = {
  token: '',
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  error: null,
  pending: false
};

export interface AuthState {
  token: string;
  isAuthenticated: boolean;
  user: any | null;
  errorMessage: string | null;
  error: string | null;
  pending: boolean;
}
export const AuthenticationReducer = createReducer(
  initialState,
  on(loginSuccess, (state, { user }) => ({
    ...state,
    user,
    isAuthenticated: true,
    error: null,
    pending: false,
  })),
  on(storeToken, (state, { token }) =>({
    ...state,
    token,
  })),
  on(logout, () => initialState),
);
export const getError = (state: AuthState) => state.error;
export const getIsAuthenticated = (state: AuthState) => state.isAuthenticated;
export const getPending = (state: AuthState) => state.pending;
export const getUser = (state: AuthState) => state.user;
