<div id="userarea-landing">
  <div class="row">
    <div class="col-sm-12 col-lg-9">
      <div class="left-panel">
        <app-userarea-controls></app-userarea-controls>
        <div class="over-heading">
          <div class="title">
            {{ 'report' | translate }}
          </div>
          <div class="order-by-wrapper">
            <div class="base--dropdown" >
              <select (ngModelChange)="onChangeFilterRequests($event)" [(ngModel)]="filter">
                <option value="all">{{ 'allP' | translate }}</option>
                <option value="in_progress">{{ 'inProgress' | translate }}</option>
                <option value="completed">{{ 'completed' | translate }}</option>
                <option value="rejected">{{ 'rejected' | translate }}</option>
                <option value="cancelled">{{ 'cancelled' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="table-reports">
          <div class="heading">
            <div class="cell id"  >
              #
            </div>
            <div class="cell"  >
              {{ 'name_companyName' | translate }}
            </div>
            <div class="cell"  >
              {{ 'alias' | translate }}
            </div>
            <div class="cell"  >
              {{ 'service' | translate }}
            </div>
            <div class="cell"  >
              {{ 'creationDate' | translate }}
            </div>
            <div class="cell"  >
              {{ 'completionDate' | translate }}
            </div>
            <div class="cell"  >
              {{ 'downloadDate' | translate }}
            </div>
            <div class="cell"  >
              {{ 'status' | translate }}
            </div>
            <div class="cell"  >
              {{ 'report' | translate }}
            </div>

          </div>
          <ng-container *ngFor="let report of reports; let i = index">
            <div class="report-row" >
              <div class="cell id">
                {{report.id}}
              </div>
              <div class="cell">
                {{report.company ? report.company.business_name : report.persons[0].name + ' ' + report.persons[0].surname}}
              </div>
              <div class="cell">
                {{report.company ? report.company.alias : ''}}
              </div>
              <div class="cell">
                {{report.service?.name}}
              </div>
              <div class="cell">
                {{getDateForDisplay(report.created_at)}}
              </div>
              <div class="cell"  >
                {{getDateForDisplay(report.completed_at)}}
              </div>
              <div class="cell">
                {{getDateForDisplay(report.download_date)}}
              </div>
              <div class="cell">
                <div class="status-wrapper" [ngSwitch]="report.status" [ngClass]="[report.status]">
                  <div *ngSwitchCase="'completed'" class="option">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#26D0CE"/>
                    </svg>
                    {{ 'completed' | translate }}
                  </div>
                  <div *ngSwitchCase="'in_progress'" class="option">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#FDC561"/>
                    </svg>
                    {{ 'inProgress' | translate }}
                  </div>
                  <div *ngSwitchCase="'pending'" class="option">
                    <!-- <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#FDC561"/>
                    </svg> -->
                    {{ 'takenOver' | translate }}
                  </div>
                  <div *ngSwitchCase="'late'" class="option">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#FDC561"/>
                    </svg>
                    {{ 'inProgress' | translate }}
                  </div>
                  <div *ngSwitchCase="'rejected'"  class="option">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#C90000"/>
                    </svg>
                    {{ 'rejected' | translate }}
                  </div>
                  <div *ngSwitchCase="'cancelled'"  class="option">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#999999"/>
                    </svg>
                    {{ 'cancelled' | translate }}
                  </div>
                </div>
              </div>
              <div class="cell">
                <div [ngSwitch]="report.check_status">
                  <div *ngSwitchCase="'checked'">
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.09467 11.2453L0.219661 6.24613C-0.0732203 5.94579 -0.0732203 5.45882 0.219661 5.15845L1.2803 4.07077C1.57318 3.7704 2.04808 3.7704 2.34096 4.07077L5.625 7.43843L12.659 0.225256C12.9519 -0.0750852 13.4268 -0.0750852 13.7197 0.225256L14.7803 1.31294C15.0732 1.61328 15.0732 2.10025 14.7803 2.40062L6.15533 11.2453C5.86242 11.5457 5.38755 11.5457 5.09467 11.2453Z" fill="#26D0CE"/>
                    </svg>
                  </div>
                  <div *ngSwitchCase="'not-checked'">
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.09467 11.2453L0.219661 6.24613C-0.0732203 5.94579 -0.0732203 5.45882 0.219661 5.15845L1.2803 4.07077C1.57318 3.7704 2.04808 3.7704 2.34096 4.07077L5.625 7.43843L12.659 0.225256C12.9519 -0.0750852 13.4268 -0.0750852 13.7197 0.225256L14.7803 1.31294C15.0732 1.61328 15.0732 2.10025 14.7803 2.40062L6.15533 11.2453C5.86242 11.5457 5.38755 11.5457 5.09467 11.2453Z" fill="#E8E8E8"/>
                    </svg>
                  </div>
                </div>
                <ng-container *ngIf="report.status === 'completed'">
                  <button class="base--button" (click)="onClickDownloadReport(report)">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2632 4.67693L11.8224 5.22196C12.0592 5.45274 12.0592 5.82591 11.8224 6.05424L6.92821 10.8269C6.69144 11.0577 6.30856 11.0577 6.07431 10.8269L1.17758 6.05424C0.940806 5.82346 0.940806 5.45028 1.17758 5.22196L1.73678 4.67693C1.97607 4.4437 2.3665 4.44861 2.60076 4.68675L5.49244 7.64513V0.58922C5.49244 0.262694 5.76196 0 6.09698 0H6.90302C7.23804 0 7.50756 0.262694 7.50756 0.58922V7.64513L10.3992 4.68675C10.6335 4.44616 11.0239 4.44125 11.2632 4.67693V4.67693Z" fill="#717272"/>
                      <rect y="13" width="13" height="2" fill="#717272"/>
                    </svg>
                  </button>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="showLoadMore">
          <button class="base--button show-more" (click)="onClickLoadMore()">
            {{ 'showMore' | translate }}
            <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1L4.5 4L7.5 1" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <app-credits></app-credits>
    </div>
  </div>

</div>
