import { Report } from "./Report";

export class Notification {
  public id: number;
  public type: string;
  public target: number;
  public is_read: number;
  public created_at: string;
  public extra: any;
  public request_id: number | null;
  public request: Report | null;

  constructor(id: number, type: string, target: number, is_read: number, created_at: string, extra: any, request_id: number | null = null, request: Report | null = null) {
    this.id = id;
    this.type = type;
    this.target = target;
    this.is_read = is_read;
    this.created_at = created_at;
    this.extra = extra;
    this.request_id = request_id;
    this.request = request;
  }
}
