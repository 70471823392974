export class Person {
  public id: string | null;
  public surname: string;
  public name: string;
  public gender: string | null;
  public birth_date: string | null;
  public fiscal_code: string | null;
  public birth_place: string |null;
  public residence: string | null;
  public residence_number: string | null;
  public residence_city: string | null;
  public residence_province: string | null;
  public nationality: string | null;
  public request_id: number | null;
  public members: null = null;
  public summary: string | null;
  public summary_links: string | string[] | null;
  public summary_keywords: string | null;

  constructor (id: string | null, surname: string, name: string, gender: string | null, birth_date: string | null, fiscal_code: string | null, birth_place: string | null, residence : string | null, residence_number: string | null, residence_city: string | null, residence_province: string | null, nationality: string | null, request_id: number | null = null, summary: string | null, summary_links: string | string[] | null, summary_keywords: string | null) {
    this.id = id;
    this.surname = surname;
    this.name = name;
    this.gender = gender;
    this.birth_date = birth_date;
    this.fiscal_code = fiscal_code;
    this.birth_place = birth_place;
    this.residence = residence;
    this.residence_number = residence_number;
    this.residence_city = residence_city;
    this.residence_province = residence_province;
    this.nationality = nationality;
    this.request_id = request_id;
    this.summary = summary;
    this.summary_links = typeof summary_links === 'string' && summary_links?.split(', ') ? summary_links?.split(', ') : summary_links;
    this.summary_keywords = summary_keywords;
  }
}
