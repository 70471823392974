import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private readonly injector: Injector,
      private translate: TranslateService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      try {
        const translateService = this.injector.get(TranslateService)
        const currentState = localStorage?.getItem("state");
        const objState = JSON.parse(currentState ? currentState : "");
        const token = objState?.authState?.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if(token && isApiUrl) {
          request = request.clone({
            setHeaders: { "Authorization": `Bearer ${token}`, "x-access-token": `${token}`, "Content-Language":  `${this.translate.currentLang}`}
          });
        }

        return next.handle(request);
      } catch {
        return next.handle(request);
      }
    }
}
