//Angular
import { Component, OnInit } from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

import { ROUTES } from 'src/app/utils/constants';
import Formatter  from "../../../../utils/formatters";

import { Service } from "src/app/models/Service";
import { ListsService } from "src/app/services/lists/lists.service";
import { UserRequestsService } from "src/app/services/user-requests/user-requests.service";
import { Person } from "src/app/models/Person";
import { Company } from "src/app/models/Company";
import { CreateUserRequestRequest } from "src/app/models/global.request";
import { Store } from "@ngrx/store";
import { CreditsState } from "src/app/stores/credits/credits.reducer";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { getNames } from "country-list";
import { Italy } from "src/app/models/Italy";

import CodiceFiscale from "codice-fiscale-js";

@Component({
  selector: "app-userarea-request",
  templateUrl: "./userarea-request.component.html",
  styleUrls: ["./userarea-request.component.scss"],

})
export class UserAreaRequestComponent {

  ROUTES = ROUTES;
  formatter!:Formatter;

  countries = getNames().sort((a, b) => a.localeCompare(b));
  italyProvinces = (new Italy()).provinces;

  services: Service[] = [];

  public utils = Formatter;

  requestTarget = new FormControl("company");

  toggleVisibleEditCompany!: boolean;
  toggleVisibleEditMember!: boolean;
  toggleVisibleAddMember: boolean = true;
  requestSuccessful: boolean = false;

  creditsState$: Observable<CreditsState>;
  creditsTotal = 0;

  showErrorCF = false;
  correctCalculatedCF = false;

  contactCompany = false;

  //Constructor
  constructor(
    public fb: FormBuilder,
    public listsService: ListsService,
    private translate: TranslateService,
    public userRequestsService: UserRequestsService,
    private store: Store<{ creditsState: CreditsState }>
  ) {
    this.formatter = new Formatter();
    this.creditsState$ = store.select('creditsState');
    this.newRequestCompanyForm.controls.country.disable();
    this.listsService.getServices().subscribe(res => {
      if (res.result && res.data) {
        this.services = this.formatter.formatResponseGetServices(res.data);
      }
    });
  }

  newRequestService = this.fb.group({
    service: ["", [Validators.required]],
  });

  newRequestCompanyForm = this.fb.group({
    area: ["italy"],
    businessName: ["", [Validators.required]],
    legalForm: ["Srl", [Validators.required]],
    alias: ["",  [Validators.required]],
    VAT: ["IT", [Validators.required]],
    registeredOffice: ["", [Validators.required]],
    country: ["Italia", [Validators.required]],
    sector: ["", [Validators.required]],
    companyListForm: this.fb.array([]) ,
  });

  newRequestMemberForm = this.fb.group({
    nameMember: ["", [Validators.required]],
    surnameMember: ["", [Validators.required]],
    genderMember: ["", [Validators.required]],
    fiscalCodeMember: ["", [Validators.required]],
    birthDateMember:["", [Validators.required]],
    birthPlaceMember:["", [Validators.required]],
    residenceMember: ["", []],
    residenceNumberMember: ["", []],
    residenceCityMember: ["", []],
    residenceProvinceMember: ["", []],
    citizenshipMember: ["", [Validators.required]],
    membersListForm: this.fb.array([]),
  }, {validator: [this.validateCF()]});

  newRequestEditMemberForm = this.fb.group({
    indexMember:[""],
    nameMember: ["", [Validators.required]],
    surnameMember: ["", [Validators.required]],
    genderMember: ["", [Validators.required]],
    fiscalCodeMember: ["", [Validators.required]],
    birthDateMember:["", [Validators.required]],
    birthPlaceMember:["", [Validators.required]],
    residenceMember: ["", []],
    residenceNumberMember: ["", []],
    residenceCityMember: ["", []],
    residenceProvinceMember: ["", []],
    citizenshipMember: ["", [Validators.required]],
    membersListForm: this.fb.array([]) ,
  }, {validator: [this.validateCF()]});

  validateCF(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      var cf = '';
      if (group.get("citizenshipMember")?.value !== 'Italy') {
        this.showErrorCF = false;
        this.correctCalculatedCF = false;
        return null;
      }
      if (
        group.get("nameMember")?.value &&
        group.get("surnameMember")?.value &&
        group.get("genderMember")?.value &&
        group.get("birthDateMember")?.value &&
        group.get("birthPlaceMember")?.value
      ) {
        try {
          cf = CodiceFiscale.compute({
            name: group.get("nameMember")?.value,
            surname: group.get("surnameMember")?.value,
            gender: group.get("genderMember")?.value,
            birthday: group.get("birthDateMember")?.value,
            birthplace: group.get("birthPlaceMember")?.value,
          });
        } catch {
          //
        }
      }
      if (group.get("fiscalCodeMember") && group.get("fiscalCodeMember")?.value && group.get("fiscalCodeMember")?.value.toUpperCase() !== cf.toUpperCase()) {
        this.showErrorCF = true;
        this.correctCalculatedCF = false;
      } else {
        if (cf && group.get("fiscalCodeMember")?.value && group.get("fiscalCodeMember")?.value.toUpperCase() === cf.toUpperCase()) {
          this.showErrorCF = false;
          this.correctCalculatedCF = true;
        } else {
          this.showErrorCF = false;
          this.correctCalculatedCF = false;
        }
      }
      return group.get("fiscalCodeMember") && group.get("fiscalCodeMember")?.value && group.get("fiscalCodeMember")?.value.toUpperCase() !== cf.toUpperCase() ? { forbidden: { message: "Il codice fiscale non corrisponde con i dati inseriti" } } : null;
    };
  }

  //Company
  get businessName() {
    return this.newRequestCompanyForm.get("businessName");
  }
  get legalForm() {
    return this.newRequestCompanyForm.get("legalForm");
  }
  get alias() {
    return this.newRequestCompanyForm.get("alias");
  }
  get VAT() {
    return this.newRequestCompanyForm.get("VAT");
  }
  get registeredOffice() {
    return this.newRequestCompanyForm.get("registeredOffice");
  }
  get country() {
    return this.newRequestCompanyForm.get("country");
  }
  get sector() {
    return this.newRequestCompanyForm.get("sector");
  }

  //List of members
  get membersListForm() {
    return this.newRequestMemberForm.get("membersListForm")  as FormArray;
  }

  get companyListForm() : FormArray {
    return this.newRequestCompanyForm.get("companyListForm") as FormArray
  }

  //Member
  get nameMember() {
    return this.newRequestMemberForm.get("nameMember");
  }
  get surnameMember() {
    return this.newRequestMemberForm.get("surnameMember");
  }
  get genderMember() {
    return this.newRequestMemberForm.get("genderMember");
  }
  get fiscalCodeMember() {
    return this.newRequestMemberForm.get("fiscalCodeMember");
  }
  get birthDateMember() {
    return this.newRequestMemberForm.get("birthDateMember");
  }
  get birthPlaceMember() {
    return this.newRequestMemberForm.get("birthPlaceMember");
  }
  get residenceMember() {
    return this.newRequestMemberForm.get("residenceMember");
  }
  get residenceNumberMember() {
    return this.newRequestMemberForm.get("residenceNumberMember");
  }
  get residenceCityMember() {
    return this.newRequestMemberForm.get("residenceCityMember");
  }
  get residenceProvinceMember() {
    return this.newRequestMemberForm.get("residenceProvinceMember");
  }
  get citizenshipMember() {
    return this.newRequestMemberForm.get("citizenshipMember");
  }


  setNameMember(value: any) {
    this.nameMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setSurnameMember(value: any) {
    this.surnameMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setGenderMember(value: any) {
    this.genderMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setFiscalCodeMember(value: any) {
    this.fiscalCodeMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setBirthDateMember(value: any) {
    this.birthDateMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setBirthPlaceMember(value: any) {
    this.birthPlaceMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setResidenceMember(value: any) {
    this.residenceMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setResidenceNumberMember(value: any) {
    this.residenceNumberMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setResidenceCityMember(value: any) {
    this.residenceCityMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setResidenceProvinceMember(value: any) {
    this.residenceProvinceMember?.setValue(value, {
      onlySelf: true,
    });
  }

  setCitizenshipMember(value: any) {
    this.citizenshipMember?.setValue(value, {
      onlySelf: true,
    });
  }


  //Edit member getters
  get indexEditMember() {
    return this.newRequestEditMemberForm.get("indexMember");
  }
  get nameEditMember() {
    return this.newRequestEditMemberForm.get("nameMember");
  }
  get surnameEditMember() {
    return this.newRequestEditMemberForm.get("surnameMember");
  }
  get genderEditMember() {
    return this.newRequestEditMemberForm.get("genderMember");
  }
  get fiscalCodeEditMember() {
    return this.newRequestEditMemberForm.get("fiscalCodeMember");
  }
  get birthDateEditMember() {
    return this.newRequestEditMemberForm.get("birthDateMember");
  }
  get birthPlaceEditMember() {
    return this.newRequestEditMemberForm.get("birthPlaceMember");
  }
  get residenceEditMember() {
    return this.newRequestEditMemberForm.get("residenceMember");
  }
  get residenceNumberEditMember() {
    return this.newRequestEditMemberForm.get("residenceNumberMember");
  }
  get residenceCityEditMember() {
    return this.newRequestEditMemberForm.get("residenceCityMember");
  }
  get residenceProvinceEditMember() {
    return this.newRequestEditMemberForm.get("residenceProvinceMember");
  }
  get citizenshipEditMember() {
    return this.newRequestEditMemberForm.get("citizenshipMember");
  }
  //edit member setters

  setIndexEditMember(value: any) {
    this.indexEditMember?.setValue(value);
  }

  setNameEditMember(value: any) {
    this.nameEditMember?.setValue(value);
  }

  setSurnameEditMember(value: any) {
    this.surnameEditMember?.setValue(value);
  }

  setGenderEditMember(value: any) {
    this.genderEditMember?.setValue(value);
  }

  setFiscalCodeEditMember(value: any) {
    this.fiscalCodeEditMember?.setValue(value);
  }

  setBirthDateEditMember(value: any) {
    this.birthDateEditMember?.setValue(value);
  }

  setBirthPlaceEditMember(value: any) {
    this.birthPlaceEditMember?.setValue(value);
  }

  setResidenceEditMember(value: any) {
    this.residenceEditMember?.setValue(value);
  }

  setResidenceNumberEditMember(value: any) {
    this.residenceNumberEditMember?.setValue(value);
  }

  setResidenceCityEditMember(value: any) {
    this.residenceCityEditMember?.setValue(value);
  }

  setResidenceProvinceEditMember(value: any) {
    this.residenceProvinceEditMember?.setValue(value);
  }

  setCitizenshipEditMember(value: any) {
    this.citizenshipEditMember?.setValue(value);
  }

  onSubmitSaveCompany() {
    const businessName = this.businessName?.value;
    const legalForm = this.legalForm?.value;
    const alias = this.alias?.value;
    const VAT = this.VAT?.value;
    const registeredOffice = this.registeredOffice?.value;
    const country = this.country?.value;
    const sector = this.sector?.value;

    const newCompany = {
      businessName,
      legalForm,
      alias,
      VAT,
      registeredOffice,
      country,
      sector,
    };

    this.addSavedCompanyList(newCompany);
    this.updateCreditsTotal();
  }

  //Company
  addSavedCompanyList(newCompany: any) {
    this.companyListForm.clear();
    this.companyListForm.push(this.newCompany(newCompany));
    this.toggleVisibleEditCompany = false;
  }

  newCompany(company: any): FormGroup {
    return this.fb.group({
      businessName: company.businessName,
      legalForm: company.legalForm,
      alias: company.alias,
      VAT: company.VAT,
      registeredOffice: company.registeredOffice,
      country: company.country,
      sector: company.sector,
    })
  }
  onClickEditCompany() {
    this.toggleVisibleEditCompany = !this.toggleVisibleEditCompany;

  }



  //Member

  onSubmitSaveMember() {

    const nameMember = (this.nameMember?.value);
    const surnameMember = this.surnameMember?.value;
    const genderMember = this.genderMember?.value;
    const fiscalCodeMember = this.fiscalCodeMember?.value;
    const birthDateMember = this.birthDateMember?.value;
    const birthPlaceMember = this.birthPlaceMember?.value;
    const residenceMember = this.residenceMember?.value;
    const residenceNumberMember = this.residenceNumberMember?.value;
    const residenceCityMember = this.residenceCityMember?.value;
    const residenceProvinceMember = this.residenceProvinceMember?.value;
    const citizenshipMember = this.citizenshipMember?.value;
    const newMember = {
      nameMember,
      surnameMember,
      genderMember,
      fiscalCodeMember,
      birthDateMember,
      birthPlaceMember,
      residenceMember,
      residenceNumberMember,
      residenceCityMember,
      residenceProvinceMember,
      citizenshipMember,
    };
    if(nameMember && surnameMember && fiscalCodeMember){
      this.addSavedMemberList(newMember);
    }
    if (this.requestTarget.value === 'person') {
      this.toggleVisibleAddMember = false;
    }

    this.updateCreditsTotal();
  }

  addSavedMemberList(newMember: any) {
    this.membersListForm.push(this.newMember(newMember));
    this.setNameMember("");
    this.setSurnameMember("");
    this.setGenderMember("");
    this.setFiscalCodeMember("");
    this.setBirthDateMember("");
    this.setBirthPlaceMember("");
    this.setResidenceMember("");
    this.setResidenceNumberMember("");
    this.setResidenceCityMember("");
    this.setResidenceProvinceMember("");
    this.setCitizenshipMember("");

    this.toggleVisibleAddMember = true;
  }

  newMember(member: any): FormGroup {
    return this.fb.group({
      nameMember: member.nameMember,
      surnameMember: member.surnameMember,
      genderMember: member.genderMember,
      fiscalCodeMember: member.fiscalCodeMember,
      birthDateMember: member.birthDateMember,
      birthPlaceMember: member.birthPlaceMember,
      residenceMember: member.residenceMember,
      residenceNumberMember: member.residenceNumberMember,
      residenceCityMember: member.residenceCityMember,
      residenceProvinceMember: member.residenceProvinceMember,
      citizenshipMember: member.citizenshipMember,

    })
  }
  onClickToggleAddMember() {
    this.toggleVisibleAddMember = !this.toggleVisibleAddMember;
  }

  //Edit Member
  onClickEditMember( indexMember: number , member: any) {

    this.setIndexEditMember(indexMember.toString());
    this.setNameEditMember(member.nameMember);
    this.setSurnameEditMember(member.surnameMember);
    this.setGenderEditMember(member.genderMember);
    this.setFiscalCodeEditMember(member.fiscalCodeMember);
    this.setBirthDateEditMember(member.birthDateMember);
    this.setBirthPlaceEditMember(member.birthPlaceMember);
    this.setResidenceEditMember(member.residenceMember);
    this.setResidenceNumberEditMember(member.residenceNumberMember);
    this.setResidenceCityEditMember(member.residenceCityMember);
    this.setResidenceProvinceEditMember(member.residenceProvinceMember);
    this.setCitizenshipEditMember(member.citizenshipMember);

    member.isExpanded = !member.isExpanded;
    if (member.isExpanded || (!member.isExpanded && this.requestTarget.value === 'person')) {
      this.toggleVisibleAddMember = false;
    } else {
      this.toggleVisibleAddMember = true;
    }
  }


  onSubmitSaveEditMember() {
    const indexMember = this.indexEditMember?.value;
    const nameMember = this.nameEditMember?.value;
    const surnameMember = this.surnameEditMember?.value;
    const genderMember = this.genderEditMember?.value;
    const fiscalCodeMember = this.fiscalCodeEditMember?.value;
    const birthDateMember = this.birthDateEditMember?.value;
    const birthPlaceMember = this.birthPlaceEditMember?.value;
    const residenceMember = this.residenceEditMember?.value;
    const residenceNumberMember = this.residenceNumberEditMember?.value;
    const residenceCityMember = this.residenceCityEditMember?.value;
    const residenceProvinceMember = this.residenceProvinceEditMember?.value;
    const citizenshipMember = this.citizenshipEditMember?.value;
    const expandVisible = false;
    const editMember = {
      nameMember,
      surnameMember,
      genderMember,
      fiscalCodeMember,
      birthDateMember,
      birthPlaceMember,
      residenceMember,
      residenceNumberMember,
      residenceCityMember,
      residenceProvinceMember,
      citizenshipMember,
      expandVisible,
    };

    if(indexMember){
      const membersArray = this.membersListForm?.value;
      membersArray[parseInt(indexMember, 10)] = editMember;
      this.membersListForm.patchValue(membersArray);
    }

    if (this.requestTarget.value === 'person') {
      this.toggleVisibleAddMember = false;
    } else {
      this.toggleVisibleAddMember = true;
    }

    this.updateCreditsTotal();
  }

  onClickResetNewMember() {
    this.setNameMember("");
    this.setSurnameMember("");
    this.setGenderMember("");
    this.setFiscalCodeMember("");
    this.setBirthDateMember("");
    this.setBirthPlaceMember("");
    this.setResidenceMember("");
    this.setResidenceNumberMember("");
    this.setResidenceCityMember("");
    this.setResidenceProvinceMember("");
    this.setCitizenshipMember("");
  }

  onClickResetEditMember() {
    this.setNameEditMember("");
    this.setSurnameEditMember("");
    this.setGenderEditMember("");
    this.setFiscalCodeEditMember("");
    this.setBirthDateEditMember("");
    this.setBirthPlaceEditMember("");
    this.setResidenceEditMember("");
    this.setResidenceNumberEditMember("");
    this.setResidenceCityEditMember("");
    this.setResidenceProvinceEditMember("");
    this.setCitizenshipEditMember("");
  }

  getVisibilityAddForm(): boolean{
    const membersArray = this.membersListForm?.value;
    if (membersArray.find((tMember: any) => tMember.isExpanded == true)) {
      return false
    }
    return true;

  }
  onClickDeleteMember(indexMember: number) {
    this.membersListForm.removeAt(indexMember);
    this.toggleVisibleAddMember = true;

    this.updateCreditsTotal();
  }

  onChangeUpdateNationFields() {
    switch (this.newRequestCompanyForm.controls.area.value) {
      case 'italy':
        this.newRequestCompanyForm.controls.country.setValue('Italia');
        this.newRequestCompanyForm.controls.country.disable();
        this.newRequestCompanyForm.controls.legalForm.setValue('Srl');
        this.newRequestCompanyForm.controls.VAT.setValue('IT');
        break;
      case 'foreign':
        this.newRequestCompanyForm.controls.country.setValue(null);
        this.newRequestCompanyForm.controls.country.enable();
        this.newRequestCompanyForm.controls.legalForm.setValue(null);
        this.newRequestCompanyForm.controls.VAT.setValue(null);
        break;
      default:
        break;
    }
  }

  isServiceSelected(name: string): boolean {
    if (name === this.newRequestService?.controls.service.value) {
      return true;
    } else {
      return false;
    }
  }

  onClickSubmitRequest() {
    let serviceIds: (number | undefined)[] = [];
      serviceIds.push(this.getIdFromServiceName(this.newRequestService.controls.service.value!));

    let persons: Person[] = [];
    Object.keys(this.membersListForm.controls).forEach(index => {
      const control = this.membersListForm.get(index) as FormGroup;
      persons.push(new Person(
        null,
        control.controls["surnameMember"].value,
        control.controls["nameMember"].value,
        control.controls["genderMember"].value,
        control.controls["birthDateMember"].value,
        control.controls["fiscalCodeMember"].value,
        control.controls["birthPlaceMember"].value,
        control.controls["residenceMember"].value,
        control.controls["residenceNumberMember"].value,
        control.controls["residenceCityMember"].value,
        control.controls["residenceProvinceMember"].value,
        control.controls["citizenshipMember"].value,
        null,
        null,
        null,
        null
      ));
    });

    // retrieve company
    let company: Company | null = null;
    if (this.requestTarget.value === 'company') {
      Object.keys(this.companyListForm.controls).forEach(index => {
        const control = this.companyListForm.get(index) as FormGroup;
        company = new Company(
          null,
          control.controls["businessName"].value,
          control.controls["legalForm"].value,
          control.controls["alias"].value,
          control.controls["VAT"].value,
          control.controls["registeredOffice"].value,
          control.controls["country"].value,
          null,
          control.controls["sector"].value,
        );
      });
    }

    let newRequest = new CreateUserRequestRequest(
      serviceIds,
      persons,
      company,
    );
    this.userRequestsService.sendRequest(newRequest).subscribe(res => {
      if (res.result && res.data) {
        this.requestSuccessful = true;
      }
    })
  }

  getIdFromServiceName(name: string) {
    return (
      this.services.find(obj => this.formatter.formatServiceFormControlName(obj.name) === name)?.id ?
      this.services.find(obj => this.formatter.formatServiceFormControlName(obj.name) === name)?.id :
      0
    );
  }

  getLowCreditsString(): string {
    return this.translate.instant('ERROR_406');
  }

  getContactCompanyString(): string {
    return this.translate.instant('contactCompany');
  }

  getServiceDescription(service: string): string {
    return this.translate.instant('description' + service.replaceAll(' ', ''));
  }

  isRequestValid() {
    if (
      (
        this.requestTarget.value === 'company' &&
        this.companyListForm.value.length === 1 &&
        this.newRequestService?.valid
      ) || (
        this.requestTarget.value === 'person' &&
        this.membersListForm.value.length === 1 &&
        this.newRequestService?.valid
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateCreditsTotal() {
    this.contactCompany = false;
    var targets = 0;

    if (this.requestTarget.value === 'company') {
      targets = this.membersListForm.length + this.companyListForm.length;
    } else {
      targets = this.membersListForm.length;
    }

    switch(this.newRequestService.controls.service.value) {
      case 'aml':
        this.creditsTotal = targets * 14;
        break;
      case 'onboarding':
        if (targets > 50) {
          this.contactCompany = true;
        } else {
          this.creditsTotal = targets * 24;
        }
        break;
      case 'esg':
        if (targets > 50) {
          this.contactCompany = true;
        } else {
          this.creditsTotal = targets * 30;
        }
        break;
      case 'wealth':
        if (targets > 50) {
          this.contactCompany = true;
        } else {
          this.creditsTotal = targets * 34;
        }
        break;
      default:
        break;
    }
  }

  getTodayDate() {
    return new Date().toISOString().split('T')[0];
  }
}

