<ng-container *ngIf="(authState$ | async); let authState;">
<div id="dashboard-home">
  <form class="heading" [formGroup]="filtersForm" (ngSubmit)="onSubmitFilterList()">
    <div class="cell" (click)="orderBy('customer')" [ngClass]="orderby !== 'customer' ? 'asc' : (orderdirDesc ? 'desc' : 'asc')">
      <div class="order-name">
        <div class="letters">
          <span>A</span>
          <span>Z</span>
        </div>
        <div class="arrow">
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989593 5.90381 0.696699 6.1967C0.403806 6.48959 0.403806 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0V11.5H6.75V0H5.25Z" fill="#999999"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="cell cell-id" (click)="openFilter('id')">
      <ng-container *ngIf="filtersVisibility.id; else showLabelId">
        <div class="filter-wrapper">
          <input type="number" class="form-control" min="1" step="1" formControlName="id" placeholder="ID">
          <button type="submit" class="base--button">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.3329 12.3329L15.7501 15.7501" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'id'" (click)="onClickResetFilter('id')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelId>ID</ng-template>
    </div>
    <div class="cell" (click)="openFilter('customer')">
      <ng-container *ngIf="filtersVisibility.customer; else showLabelClient">
        <div class="filter-wrapper">
          <input type="text" class="form-control" formControlName="customer" placeholder="Cliente">
          <button type="submit" class="base--button">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.3329 12.3329L15.7501 15.7501" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'customer'" (click)="onClickResetFilter('customer')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelClient>
        {{ 'client' | translate }}
        <div class="arrow">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
          </svg>
        </div>
      </ng-template>
    </div>
    <div class="cell" (click)="openFilter('target')">
      <ng-container *ngIf="filtersVisibility.target; else showLabelTarget">
        <div class="filter-wrapper">
          <input type="text" class="form-control" formControlName="target" placeholder="Target">
          <button type="submit" class="base--button">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.3329 12.3329L15.7501 15.7501" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'target'" (click)="onClickResetFilter('target')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelTarget>
        {{ 'target' | translate }}
        <div class="arrow">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
          </svg>
        </div>
      </ng-template>
    </div>
    <div class="cell" (click)="openFilter('service_id')">
      <ng-container *ngIf="filtersVisibility.service_id; else showLabelService">
        <div class="filter-wrapper">
          <select class="form-control" formControlName="service_id" (change)="onSubmitFilterList()">
            <option value="" disabled selected hidden>{{ 'service' | translate }}</option>
            <ng-container *ngFor="let service of services" >
              <option value="{{service.id}}">{{service.name}}</option>
            </ng-container>
          </select>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'service_id'" (click)="onClickResetFilter('service_id')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelService>
        {{ 'service' | translate }}
        <div class="arrow">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
          </svg>
        </div>
      </ng-template>
    </div>
    <div class="cell" (click)="openFilter('priority')">
      <ng-container *ngIf="filtersVisibility.priority; else showLabelPriority">
        <div class="filter-wrapper">
          <select class="form-control" formControlName="priority" (change)="onSubmitFilterList()">
            <option value="" disabled selected hidden>{{ 'priority' | translate }}</option>
            <option value="low">{{ 'lowF' | translate }}</option>
            <option value="medium">{{ 'mediumF' | translate }}</option>
            <option value="high">{{ 'highF' | translate }}</option>
          </select>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'priority'" (click)="onClickResetFilter('priority')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelPriority>
        {{ 'priority' | translate }}
        <div class="arrow">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
          </svg>
        </div>
      </ng-template>
    </div>
    <div class="cell" (click)="orderBy('expiry_date')" [ngClass]="orderby !== 'expiry_date' ? 'asc' : (orderdirDesc ? 'desc' : 'asc')">
      {{ 'deadline' | translate }}
      <div class="arrow">
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
        </svg>
      </div>
    </div>
    <div class="cell" (click)="openFilter('status')">
      <ng-container *ngIf="filtersVisibility.status; else showLabelStatus">
        <div class="filter-wrapper">
          <select class="form-control" formControlName="status" (change)="onSubmitFilterList()">
            <option value="" disabled selected hidden>{{ 'status' | translate }}</option>
            <option value="pending">{{ 'pending' | translate }}</option>
            <option value="in_progress">{{ 'inProgress' | translate }}</option>
            <option value="completed">{{ 'completed' | translate }}</option>
            <option value="rejected">{{ 'rejected' | translate }}</option>
            <option value="late">{{ 'late' | translate }}</option>
            <option value="cancelled">{{ 'cancelled' | translate }}</option>
          </select>
          <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'status'" (click)="onClickResetFilter('status')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-template #showLabelStatus>
        {{ 'status' | translate }}
        <div class="arrow">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
          </svg>
        </div>
      </ng-template>
    </div>
    <ng-container *ngIf="authState?.user?.role ==='administrator'; else operatorNoFilter">
      <div class="cell" (click)="openFilter('operator_id')">
        <ng-container *ngIf="filtersVisibility.operator_id; else showLabelHolder">
          <div class="filter-wrapper">
            <select class="form-control" formControlName="operator_id" (change)="onSubmitFilterList()">
              <option value="" disabled selected hidden>{{ 'holder' | translate }}</option>
              <option value="-1">{{ 'notAssigned' | translate }}</option>
              <ng-container *ngFor="let operator of operators" >
                <option value="{{operator.id}}">{{formatter.formatGetFirstChar(operator.first_name)}}. {{operator.last_name}}</option>
              </ng-container>
            </select>
            <button type="button" class="base--button reset-filter" *ngIf="filters | isFilterPresent: 'operator_id'" (click)="onClickResetFilter('operator_id')">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.625 1.375L1.375 12.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.625 12.625L1.375 1.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </ng-container>
        <ng-template #showLabelHolder>
          {{ 'holder' | translate }}
          <div class="arrow">
            <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.34683 1.90516L3.37671 4.79076C3.83294 5.22527 4.56994 5.22527 5.02618 4.79076L8.05605 1.90516C8.79305 1.20326 8.26662 0 7.22547 0H1.16572C0.124561 0 -0.390167 1.20326 0.34683 1.90516Z" fill="#999999"/>
            </svg>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #operatorNoFilter>
      <div class="cell">
        {{ 'holder' | translate }}
      </div>
    </ng-template>
  </form>
  <ng-container *ngFor="let target of targets; let i = index">
    <div class="company-row" (click)="target.is_expanded = !target.is_expanded;"  [ngClass]="[target.is_expanded ? 'expanded' : 'closed']">
      <div class="cell"></div>
      <div class="cell cell-id">
        {{target.id}}
      </div>
      <div class="cell">
        {{target.customer?.last_name + ' ' + target.customer?.first_name}}
      </div>
      <div class="cell">
        {{target.company ? target.company.business_name : target.persons[0].name + ' ' + target.persons[0].surname}}
      </div>
      <div class="cell">
        {{target.service?.name}}
      </div>
      <div class="cell">
        <div class="base--dropdown priority" [ngClass]="target?.priority">
          <ng-container *ngIf="authState?.user?.role ==='administrator'; else priorityOperator">
            <select #priorities (change)="onChangeSavePriority(priorities.value, target, i);" (click) ="$event.stopPropagation();" [value]="target.priority">
              <option value="low">{{ 'lowF' | translate }}</option>
              <option value="medium">{{ 'mediumF' | translate }}</option>
              <option value="high">{{ 'highF' | translate }}</option>
            </select>
          </ng-container>
          <ng-template #priorityOperator>
            <div class="operator-value">{{ target?.priority + 'F' | translate }}</div>
          </ng-template>
        </div>
      </div>
      <div class="cell">
        <div class="deadline-wrapper">
          <ng-container *ngIf="authState?.user?.role ==='administrator'; else deadlineOperator">
            <input #deadline type="date" [min]="getTodayDate()" [value]="getDateForInput(target.expiry_date)" (click) ="$event.stopPropagation();" (change)="onChangeSaveDeadline(deadline.value, target, i);" [disabled]="authState?.user?.role ==='operator'">
          </ng-container>
          <ng-template #deadlineOperator>
            {{getDateForDisplay(target.expiry_date)}}
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 3H3C2.72386 3 2.5 3.22386 2.5 3.5V13.5C2.5 13.7761 2.72386 14 3 14H13C13.2761 14 13.5 13.7761 13.5 13.5V3.5C13.5 3.22386 13.2761 3 13 3Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 2V4" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 2V4" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.5 6H13.5" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </ng-template>
        </div>
      </div>
      <div class="cell">
          <div class="base--dropdown">
            <ng-container *ngIf="authState?.user?.role ==='administrator'; else statusOperator">
            <select #status (change)="onChangeSaveStatus(status.value,target, i);" (click) ="$event.stopPropagation();" [value]="target.status" [disabled]="!target?.operator">
              <option value="pending">{{ 'pending' | translate }}</option>
              <option value="in_progress">{{ 'inProgress' | translate }}</option>
              <option value="completed">{{ 'completed' | translate }}</option>
              <option value="rejected">{{ 'rejected' | translate }}</option>
              <option value="late">{{ 'late' | translate }}</option>
              <option value="cancelled">{{ 'cancelled' | translate }}</option>
            </select>
          </ng-container>
          <ng-template #statusOperator>
            <select #status (change)="onChangeSaveStatus(status.value,target, i);" (click) ="$event.stopPropagation();" [value]="target.status" [disabled]="!target?.operator">
              <option value="pending" disabled>{{ 'pending' | translate }}</option>
              <option value="in_progress">{{ 'inProgress' | translate }}</option>
              <option value="completed">{{ 'completed' | translate }}</option>
              <option value="rejected" disabled>{{ 'rejected' | translate }}</option>
              <option value="late" disabled>{{ 'late' | translate }}</option>
              <option value="cancelled" disabled>{{ 'cancelled' | translate }}</option>
            </select>
          </ng-template>
        </div>
      </div>
      <div class="cell">
        <div class="base--dropdown" [ngClass]="target?.operator ? '' : 'not-assigned'">
          <select #holder (change)="onChangeSaveHolder(holder.value, target, i);" (click) ="$event.stopPropagation();" [value]="target.operator_id">
            <ng-container *ngIf="!target?.operator">
              <option value="not-assigned" [selected]="!target?.operator">{{ 'notAssigned' | translate }}</option>
            </ng-container>
            <ng-container *ngIf="authState.user.role === 'administrator'; else assignOperator">
              <ng-container *ngFor="let operator of operators" >
                <option value="{{operator.id}}" [selected]="target?.operator?.id == operator.id">{{formatter.formatGetFirstChar(operator.first_name)}}. {{operator.last_name}}</option>
              </ng-container>
            </ng-container>
            <ng-template #assignOperator>
              <option value="{{ authState.user.id }}" [selected]="target?.operator?.id == authState.user.id">{{formatter.formatGetFirstChar(authState.user.first_name)}}. {{authState.user.last_name}}</option>
            </ng-template>
          </select>
        </div>
      </div>
    </div>
    <div class="expanded-panel" [ngClass]="[target.is_expanded ? 'expanded' : 'closed']" >
      <ng-container *ngIf="target.company">
      <div class="sub-heading">
        <div class="sub-cell id">
          #
        </div>
        <div class="sub-cell">
          {{ 'name_companyName' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'legalForm' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'registeredOffice' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'country' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'vat_fiscalCode' | translate }}
        </div>
        <div class="sub-cell"  >
        </div>
      </div>
      <div class="member-row">
        <div class="sub-cell id">
          {{target.company.id}}
        </div>
        <div class="sub-cell strong">
          {{target.company.business_name}}
        </div>
        <div class="sub-cell">
          {{target.company.legal_form}}
        </div>
        <div class="sub-cell">
          {{target.company.head_office}}
        </div>
        <div class="sub-cell">
          {{target.company.country}}
        </div>
        <div class="sub-cell">
          {{target.company.vat_number}}
        </div>
        <div class="sub-cell actions">
          <ng-container *ngIf="authState?.user?.id === target.operator_id && (target.status !== 'completed' || (target.status === 'completed' && authState?.user?.role ==='administrator'))">
            <a class="btn-work-wrapper"  [routerLink]="[ROUTES.ROUTE_REPORT, target.id, 'company', target.company.id]">
                <span>{{ 'edit' | translate }}</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.0625 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V15.1875C14.625 15.3367 14.5657 15.4798 14.4602 15.5852C14.3548 15.6907 14.2117 15.75 14.0625 15.75Z" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6875 2.25V6.1875H14.625" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.75 9.5625H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.75 11.8125H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            </ng-container>
            <ng-container *ngIf="target.status === 'completed'">
              <a class="btn-work-wrapper" (click)="downloadReportCompany(target, target.company)">
                <span>{{ 'download' | translate }}</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.0625 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V15.1875C14.625 15.3367 14.5657 15.4798 14.4602 15.5852C14.3548 15.6907 14.2117 15.75 14.0625 15.75Z" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6875 2.25V6.1875H14.625" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.75 9.5625H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.75 11.8125H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </ng-container>
        </div>
      </div>
      </ng-container>

      <ng-container *ngIf="target.persons.length !== 0">
      <div class="sub-heading">
        <div class="sub-cell id">
          #
        </div>
        <div class="sub-cell">
          {{ 'surname' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'name' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'dateOfBirth' | translate }}
        </div>
        <div class="sub-cell fiscal-code">
          {{ 'fiscalCode' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'bornIn' | translate }}
        </div>
        <div class="sub-cell">
          {{ 'residence' | translate }}
        </div>
        <div class="sub-cell"  >
          {{ 'citizenship' | translate }}
        </div>
        <div class="sub-cell"  >
        </div>
      </div>
      <div class="member-row" *ngFor="let member of target.persons; let i = index">
        <div class="sub-cell id">
          {{member.id}}
        </div>
        <div class="sub-cell strong">
          {{member.surname}}
        </div>
        <div class="sub-cell">
          {{member.name}}
        </div>
        <div class="sub-cell">
          {{getDateForDisplay(member.birth_date)}}
        </div>
        <div class="sub-cell fiscal-code">
          {{member.fiscal_code}}
        </div>
        <div class="sub-cell">
          {{member.birth_place}}
        </div>
        <div class="sub-cell">
          {{member.residence}} {{ member.residence_number }}{{ (member.residence_city ? ', ' + member.residence_city : '') }}{{ member.residence_province ? ' (' + member.residence_province + ')' : '' }}
        </div>
        <div class="sub-cell">
          {{member.nationality}}
        </div>
        <div class="sub-cell actions">
          <ng-container *ngIf="authState?.user?.id === target.operator_id && (target.status !== 'completed' || (target.status === 'completed' && authState?.user?.role ==='administrator'))">
          <a class="btn-work-wrapper"  [routerLink]="[ROUTES.ROUTE_REPORT, target.id, 'person', member.id]">
              <span>{{ 'edit' | translate }}</span>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0625 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V15.1875C14.625 15.3367 14.5657 15.4798 14.4602 15.5852C14.3548 15.6907 14.2117 15.75 14.0625 15.75Z" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6875 2.25V6.1875H14.625" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 9.5625H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 11.8125H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </a>
          </ng-container>
          <ng-container *ngIf="target.status === 'completed'">
            <a class="btn-work-wrapper" (click)="downloadReportPerson(target, member)">
              <span>{{ 'download' | translate }}</span>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0625 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V15.1875C14.625 15.3367 14.5657 15.4798 14.4602 15.5852C14.3548 15.6907 14.2117 15.75 14.0625 15.75Z" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6875 2.25V6.1875H14.625" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 9.5625H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 11.8125H11.25" stroke="#4D4D4D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </ng-container>
        </div>
      </div>
      </ng-container>
    </div>

    <ng-container *ngIf="target.company">
      <div class="company-cards" [ngClass]="[target.is_expanded ? 'hide' : 'show']">
        <div *ngIf="target.persons.length === 0" class="company-card company-card-0"></div>
        <div *ngFor="let member of target.persons; let i = index">
          <div *ngIf="i < 4" class="company-card company-card-{{i}}"></div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showLoadMore">
    <button class="base--button show-more" (click)="onClickLoadMore()">
      {{ 'showMore' | translate }}
      <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 1L4.5 4L7.5 1" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </ng-container>
  <div>

  </div>
</div>
</ng-container>
