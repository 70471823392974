import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthState } from '../../stores/auth/authentication.reducer';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState$: Observable<AuthState>;
  isAuthenticated !: boolean;
  userRole!: string;
  reqHeader!:HttpHeaders;

  constructor(
    private http: HttpClient,
    private store: Store<{ authState: AuthState }>,
    ) {
      this.authState$ = store.select('authState');
  }

  public verifyUser(): Observable<any> {
      return this.http.get<any[]>(`${environment.apiUrl}user/profile`);
  }

  public getStatistics(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}user/statistics`);
  }

  public getRole(): string{
    this.authState$.subscribe(authS =>{
      this.userRole = authS?.user?.role;
    })
    return this.userRole;
  }
  public getAuthStatus(): boolean {
    this.authState$.subscribe(authS =>{
        this.isAuthenticated = authS.isAuthenticated;
    })

    return this.isAuthenticated;
  }
}
