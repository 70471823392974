import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaginationParams } from 'src/app/models/global.request';
import Formatter from 'src/app/utils/formatters';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  formatter!: Formatter;

  constructor(
    private http: HttpClient,
  ) {
    this.formatter = new Formatter();
  }

  public getNotifications(params: PaginationParams): Observable<any> {
    const p = this.formatter.formatRequestPagination(params);
    return this.http.get<any[]>(`${environment.apiUrl}user/notifications`, {params: p});
  }

  public getNotificationsUnread(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}user/notifications/unread`);
  }

  public readNotification(id: number, is_read: number): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}user/notifications/${id}/read`, {is_read: is_read});
  }

  public archiveNotification(id: number): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}user/notifications/${id}/archive`, null);
  }

}
