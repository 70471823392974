import { UserAreaControlsComponent } from './components/shared/userarea-controls/userarea-controls.component';
import { UserAreaRequestComponent } from './components/userarea-request/userarea-request.component';
import { UserAreaUploadComponent } from './components/userarea-upload/userarea-upload.component';
//Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


//Routing
import { UserAreaRoutingModule } from './userarea-routing.module';

//Components
import { UserAreaLandingComponent } from './components/userarea-landing/userarea-landing.component';
import { CreditsComponent } from 'src/app/shared/components/credits/credits.component';

import {
  popperVariation,
  provideTippyConfig,
  TippyDirective,
  tooltipVariation,
  withContextMenuVariation
} from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    UserAreaLandingComponent,
    UserAreaRequestComponent,
    UserAreaUploadComponent,
    CreditsComponent,
    UserAreaControlsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UserAreaRoutingModule,
    TranslateModule,
    TippyDirective
  ],
  providers: [
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        menu: {
          ...popperVariation,
          appendTo: 'parent',
          arrow: false,
          offset: [0, 0]
        },
        contextMenu: withContextMenuVariation(popperVariation),
        popperBorder: {
          ...popperVariation,
          theme: 'light-border'
        }
      }
    })
  ],
  exports:[
    UserAreaLandingComponent,
    UserAreaRequestComponent,
    UserAreaUploadComponent,
  ],
})
export class UserAreaModule { }
