export const ROUTES_ADMIN = [
  "/dashboard",
  "/report",
]
export const ROUTES_CUSTOMER = [
  "/home",
  "/new-request",
]

export const ROUTES = {
  ROUTE_HOME:"/home",
  ROUTE_NEW_REQUEST:"/new-request",
  ROUTE_DASHBOARD:"/dashboard",
  ROUTE_REPORT: "/report",
  ROUTE_UPLOAD:"/upload",
}
