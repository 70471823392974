
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth/authorization.guards';
import { DashboardHomeComponent } from './components/dash-home/dash-home.component';
import { DashboardReportComponent } from './components/dash-report/dash-report.component';



const routes: Routes = [
  {path: 'dashboard', component: DashboardHomeComponent, canActivate: [AuthGuard],data: {role: 'administrator | operator'}},
  {path: 'report/:id/:target_type/:id_target', component: DashboardReportComponent, canActivate: [AuthGuard],data: {role: 'administrator | operator'}},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
