import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, mergeMap, retry, retryWhen, tap } from 'rxjs/operators';
import { Toast, ToastrService } from "ngx-toastr";
import { AuthState } from '../stores/auth/authentication.reducer';
import { logout } from '../stores/auth/authentication.actions';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  authState$: Observable<AuthState>;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private store: Store<{ authState: AuthState }>)
    {
      this.authState$ = store.select('authState');
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((evt) => {
          if (evt instanceof HttpResponse) {
            const isApiUrl = request.url.startsWith(environment.apiUrl);
            if (isApiUrl && !(evt.body instanceof Blob) && !evt.body.result) {
              this.translate.get('ERROR_'+evt.body.error_code).subscribe(translation => {
                if (translation) {
                  this.toastr.error(translation);
                } else {
                  this.toastr.error(evt.body.error_description);
                }
              });
              if (evt.body.error_code === 401 || evt.body.error_code === 403) {
                this.store.dispatch(logout());
                this.router.navigate(['/login']);
              }
            } else if (isApiUrl && evt.body instanceof Blob && evt.headers.get('Content-Type')?.includes('application/json')) {
              this.translate.get('ERROR_404').subscribe(translation => {
                if (translation) {
                  this.toastr.error(translation);
                } else {
                  this.toastr.error(evt.body.error_description);
                }
              });
            }
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          let errorMessage = '';
          errorMessage = errorResponse?.error?.message;

          if (errorMessage === 'Unauthorized!' || errorMessage === 'No token provided!') {
            this.store.dispatch(logout());
          }

          if(errorMessage)
            this.toastr.error(errorMessage);

          return throwError(() => new Error(errorMessage?errorMessage:"Error Server"));
        })
      )
  }
}
