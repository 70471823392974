//Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Routing Files
import { DashboardRoutingModule } from './dashboard-routing.module';

//Components
import { DashboardHomeComponent } from './components/dash-home/dash-home.component';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardReportComponent } from './components/dash-report/dash-report.component';

//Capture
import { NgxCaptureModule } from 'ngx-capture';

import { SafePipe } from 'src/app/utils/transformers';
import { IsFilterPresentPipe } from 'src/app/utils/transformers';

@NgModule({
  declarations: [
    SafePipe,
    IsFilterPresentPipe,
    DashboardHomeComponent,
    DashboardReportComponent,
  ],
  imports: [
    CommonModule,
    NgxCaptureModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    DashboardRoutingModule,
    TranslateModule,
  ],
  exports:[
    DashboardHomeComponent,
    DashboardReportComponent,
  ]
})
export class DashboardModule { }
