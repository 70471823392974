
import { createReducer, on } from '@ngrx/store';
import { toggle, close, unread } from './notifications.actions';

export const initialState: NotificationsState = {
  isOpenNotifications: false,
  unreadCount: 0,
};

export interface NotificationsState {
  isOpenNotifications: boolean;
  unreadCount: number;
}

export const NotificationsReducer = createReducer(
  initialState,
  on(toggle, (state) => ({
    ...state,
    isOpenNotifications: !state.isOpenNotifications,
  })),
  on(unread, (state, { count }) => ({
    ...state,
    unreadCount: count,
  })),
  on(close, (state) => ({
    ...state,
    isOpenNotifications: false,
  }))
);
