import { Component } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { NotificationsState } from '../../../stores/notifications/notifications.reducer';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { AuthState } from 'src/app/stores/auth/authentication.reducer';
import { CreditsState } from 'src/app/stores/credits/credits.reducer';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { credits } from 'src/app/stores/credits/credits.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent {

  count_completed = 0;
  count_pending = 0;
  count_progress = 0;
  count_late = 0;
  count_rejected = 0;
  count_sent = 0;

  authState$: Observable<AuthState>;
  notificationsState$: Observable<NotificationsState>;
  creditsState$: Observable<CreditsState>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthenticationService,
    private store: Store<{ authState: AuthState, notificationsState: NotificationsState, creditsState: CreditsState }>
  ) {
    this.authState$ = store.select('authState');
    this.notificationsState$ = store.select('notificationsState');
    this.creditsState$ = store.select('creditsState');

    this.authService.verifyUser().subscribe(res => {
      if (res.result && res.data) {
        this.store.dispatch(credits({available: res.data.credits}));
      }
    });

    this.authService.getStatistics().subscribe(res => {
      if (res.result && res.data) {
        this.count_completed = res.data.count_completed;
        this.count_pending = res.data.count_pending;
        this.count_progress = res.data.count_progress;
        this.count_late = res.data.count_late;
        this.count_rejected = res.data.count_rejected;
        this.count_sent = res.data.count_sent;
      }
    });
  }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.changeHeaderTheme(event.url);
      }
    });

  }
  onClickGoToStore() {
    const shopURL = this.translate.currentLang == 'en' ? environment.commerceUrl + 'en/shop/' : environment.commerceUrl + 'shop/';
    window.open(
      shopURL,
      '_blank'
    );
  }

}
