//Angular
import { Component, Inject } from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { Observable } from "rxjs";
import { storeToken } from "src/app/stores/auth/authentication.actions";
import { AuthState } from "src/app/stores/auth/authentication.reducer";
import { ROUTES } from "src/app/utils/constants";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],

})
export class LoginComponent {
  roleUser!: string;
  authState$:  Observable<AuthState>;
  action: string = 'checking';

  //Constructor
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private store: Store<{ authState: AuthState }>
  ) {
    this.authState$ = store.select('authState');
  }
  // On Component Initialized
  ngOnInit(): void {
    this.authState$.subscribe(authS => {
      this.roleUser = authS?.user?.role;
      switch (this.roleUser) {
        case "administrator":
          this.router.navigate([ROUTES.ROUTE_DASHBOARD]);
          break;
        case "operator":
          this.router.navigate([ROUTES.ROUTE_DASHBOARD]);
          break;
        case "customer":
          this.router.navigate([ROUTES.ROUTE_HOME]);
          break;
        default:
          break;
      }
    });

    this.activatedRouter.queryParams.subscribe((res: any) => {
      if (res.t) {  // && this.document.referrer.includes(environment.commerceUrl)
        this.store.dispatch(storeToken({token: res.t}));
      } else {
        if (res.action) {
          this.action = res.action;
        } else {
          this.action = 'denied';
        }
      }
    })
  }
}

