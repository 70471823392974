export class Italy {
  regions: string[] = [
    "Abruzzo", "Basilicata", "Calabria", "Campania", "Emilia-Romagna", "Friuli-Venezia Giulia", "Lazio", "Liguria", "Lombardia", "Marche", "Molise", "Piemonte", "Puglia", "Sardegna", "Sicilia", "Toscana", "Trentino-Alto Adige", "Umbria", "Valle d'Aosta", "Veneto"
  ];

  provinces = [
    {key: "AG", value: "Agrigento"},
    {key: "AL", value: "Alessandria"},
    {key: "AN", value: "Ancona"},
    {key: "AO", value: "Aosta"},
    {key: "AP", value: "Ascoli Piceno"},
    {key: "AQ", value: "L'Aquila"},
    {key: "AR", value: "Arezzo"},
    {key: "AT", value: "Asti"},
    {key: "AV", value: "Avellino"},
    {key: "BA", value: "Bari"},
    {key: "BG", value: "Bergamo"},
    {key: "BI", value: "Biella"},
    {key: "BL", value: "Belluno"},
    {key: "BN", value: "Benevento"},
    {key: "BO", value: "Bologna"},
    {key: "BR", value: "Brindisi"},
    {key: "BS", value: "Brescia"},
    {key: "BT", value: "Barletta-Andria-Trani"},
    {key: "BZ", value: "Bolzano"},
    {key: "CA", value: "Cagliari"},
    {key: "CB", value: "Campobasso"},
    {key: "CE", value: "Caserta"},
    {key: "CH", value: "Chieti"},
    {key: "CI", value: "Carbonia-Iglesias"},
    {key: "CL", value: "Caltanissetta"},
    {key: "CN", value: "Cuneo"},
    {key: "CO", value: "Como"},
    {key: "CR", value: "Cremona"},
    {key: "CS", value: "Cosenza"},
    {key: "CT", value: "Catania"},
    {key: "CZ", value: "Catanzaro"},
    {key: "EN", value: "Enna"},
    {key: "FC", value: "Forlì-Cesena"},
    {key: "FE", value: "Ferrara"},
    {key: "FG", value: "Foggia"},
    {key: "FI", value: "Firenze"},
    {key: "FM", value: "Fermo"},
    {key: "FR", value: "Frosinone"},
    {key: "GE", value: "Genova"},
    {key: "GO", value: "Gorizia"},
    {key: "GR", value: "Grosseto"},
    {key: "IM", value: "Imperia"},
    {key: "IS", value: "Isernia"},
    {key: "KR", value: "Crotone"},
    {key: "LC", value: "Lecco"},
    {key: "LE", value: "Lecce"},
    {key: "LI", value: "Livorno"},
    {key: "LO", value: "Lodi"},
    {key: "LT", value: "Latina"},
    {key: "LU", value: "Lucca"},
    {key: "MB", value: "Monza e Brianza"},
    {key: "MC", value: "Macerata"},
    {key: "ME", value: "Messina"},
    {key: "MI", value: "Milano"},
    {key: "MN", value: "Mantova"},
    {key: "MO", value: "Modena"},
    {key: "MS", value: "Massa e Carrara"},
    {key: "MT", value: "Matera"},
    {key: "NA", value: "Napoli"},
    {key: "NO", value: "Novara"},
    {key: "NU", value: "Nuoro"},
    {key: "OG", value: "Ogliastra"},
    {key: "OR", value: "Oristano"},
    {key: "OT", value: "Olbia-Tempio"},
    {key: "PA", value: "Palermo"},
    {key: "PC", value: "Piacenza"},
    {key: "PD", value: "Padova"},
    {key: "PE", value: "Pescara"},
    {key: "PG", value: "Perugia"},
    {key: "PI", value: "Pisa"},
    {key: "PN", value: "Pordenone"},
    {key: "PO", value: "Prato"},
    {key: "PR", value: "Parma"},
    {key: "PT", value: "Pistoia"},
    {key: "PU", value: "Pesaro e Urbino"},
    {key: "PV", value: "Pavia"},
    {key: "PZ", value: "Potenza"},
    {key: "RA", value: "Ravenna"},
    {key: "RC", value: "Reggio Calabria"},
    {key: "RE", value: "Reggio Emilia"},
    {key: "RG", value: "Ragusa"},
    {key: "RI", value: "Rieti"},
    {key: "RM", value: "Roma"},
    {key: "RN", value: "Rimini"},
    {key: "RO", value: "Rovigo"},
    {key: "SA", value: "Salerno"},
    {key: "SI", value: "Siena"},
    {key: "SO", value: "Sondrio"},
    {key: "SP", value: "La Spezia"},
    {key: "SR", value: "Siracusa"},
    {key: "SS", value: "Sassari"},
    {key: "SV", value: "Savona"},
    {key: "TA", value: "Taranto"},
    {key: "TE", value: "Teramo"},
    {key: "TN", value: "Trento"},
    {key: "TO", value: "Torino"},
    {key: "TP", value: "Trapani"},
    {key: "TR", value: "Terni"},
    {key: "TS", value: "Trieste"},
    {key: "TV", value: "Treviso"},
    {key: "UD", value: "Udine"},
    {key: "VA", value: "Varese"},
    {key: "VB", value: "Verbano Cusio Ossola"},
    {key: "VC", value: "Vercelli"},
    {key: "VE", value: "Venezia"},
    {key: "VI", value: "Vicenza"},
    {key: "VR", value: "Verona"},
    {key: "VS", value: "Medio Campidano"},
    {key: "VT", value: "Viterbo"},
    {key: "VV", value: "Vibo Valentia"},
  ]
}
