export class ResearchHit {
  id: number | null;
  person_id: number;
  title: string;
  url: string;
  notes: string;
  type: string;
  severity: string;
  research_hit_categories: number[];
  attachments: ResearchHitAttachment[];
  attachment: File | null = null;
  attachment_blob: Blob | null = null;
  constructor(id: number | null, person_id: number, title: string, url: string, notes: string, type: string, severity: string, research_hit_categories: number[], attachments: ResearchHitAttachment[]) {
    this.id = id;
    this.person_id = person_id;
    this.title = title;
    this.url = url;
    this.notes = notes;
    this.type = type;
    this.severity = severity;
    this.research_hit_categories = research_hit_categories;
    this.attachments = attachments;
  }
}

export class ResearchHitAttachment {
  id: number;
  filename: string;
  type: string;
  research_hit_id: number;

  constructor(id: number, filename: string, type: string, research_hit_id: number) {
    this.id = id;
    this.filename = filename;
    this.type = type;
    this.research_hit_id = research_hit_id;
  }
}
