<ng-container *ngIf="(authState$ | async); let authState;">
<ng-container *ngIf="(creditsState$ | async); let creditsState;">
<div id="credits-panel">
  <div class="top">
    <span class="welcome">
      {{ 'hello' | translate }}
    </span>
    <span class="fullname">{{authState?.user?.first_name}}<br/>{{authState?.user?.last_name}}</span>
    <!-- <span class="place">Milano, IT</span> -->
  </div>
  <div class="content">
    <span class="title">{{ 'remainingCredits' | translate }}</span>
    <div class="credits">
      <span class="value">{{ creditsState?.available }}</span>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 21C13.5 23.4855 17.529 25.5 22.5 25.5C27.471 25.5 31.5 23.4855 31.5 21C31.5 18.5145 27.471 16.5 22.5 16.5C17.529 16.5 13.5 18.5145 13.5 21Z" stroke="#717272" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.5 21V27C13.5 29.484 17.529 31.5 22.5 31.5C27.471 31.5 31.5 29.484 31.5 27V21M4.5 9C4.5 10.608 6.216 12.093 9 12.897C11.784 13.701 15.216 13.701 18 12.897C20.784 12.093 22.5 10.608 22.5 9C22.5 7.392 20.784 5.907 18 5.103C15.216 4.299 11.784 4.299 9 5.103C6.216 5.907 4.5 7.392 4.5 9Z" stroke="#717272" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 9V24C4.5 25.332 5.658 26.175 7.5 27" stroke="#717272" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 16.5C4.5 17.832 5.658 18.675 7.5 19.5" stroke="#717272" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="buy-wrapper">
      <button class="btn-buy" (click)="onClickGoToStore()">
        {{ 'buyCredits' | translate }}
      </button>
    </div>
  </div>
  <div class="bottom">
    <div class="request-list">
      <div class="request">
        <span>{{ 'requestsSent' | translate }}</span>
        <div></div>
        <span>{{ count_sent }}</span>
      </div>
      <div class="request">
        <span>{{ 'requestsCompleted' | translate }}</span>
        <div></div>
        <span>{{ count_completed }}</span>
      </div>
      <div class="request">
        <span>{{ 'requestsRejected' | translate }}</span>
        <div></div>
        <span>{{ count_rejected }}</span>
      </div>
      <div class="request">
        <span>{{ 'requestsProgress' | translate }}</span>
        <div></div>
        <span>{{ count_progress + count_late }}</span>
      </div>
    </div>
  </div>
  <div class="help">
    <p>{{ 'needHelp' | translate }}<br><a href="mailto:support@hintegrity.it">support@hintegrity.it</a></p>
  </div>
</div>
</ng-container>
</ng-container>
