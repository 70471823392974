import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { User } from '../../models/User';

export const logout = createAction('[Auth] Logout');

export const loginSuccess = createAction(
  '[Auth/API] Login Success',
  props<{ user: User }>()
);

export const storeToken = createAction(
  '[Auth/API] Store Token',
  props<{ token: string }>()
);

export const idleTimeout = createAction('[User] Idle Timeout');
