import { Component } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

//Actions
import { toggle } from '../../../stores/notifications/notifications.actions';
import { logout } from 'src/app/stores/auth/authentication.actions';

//States
import { AuthState } from '../../../stores/auth/authentication.reducer';
import { NotificationsState } from '../../../stores/notifications/notifications.reducer';

import Formatter  from "../../../utils/formatters";
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  env = environment;
  formatter!:Formatter;

  bgColor: string | undefined;
  notificationsState$: Observable<NotificationsState>;
  authState$: Observable<AuthState>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: Store<{ authState: AuthState, notificationsState: NotificationsState }>
    )
  {
    this.formatter = new Formatter();
    this.authState$ = store.select('authState');
    this.notificationsState$ = store.select('notificationsState');
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.changeHeaderTheme(event.url);
      }
    });
  }

  getRouteByRole(): any {
    let routePath: any = []
    routePath.push('/home');
    // routePath.push('/dashboard');
    return routePath;
  }

  onClickLogout() {
    window.open(
      environment.commerceUrl + 'logout',
      '_blank'
    );
    this.store.dispatch(logout());
    window.top?.close();
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }

  isCurrentLanguage(lang: string) {
    return this.translate.currentLang === lang ? true : false;
  }

  toggle() {
    this.store.dispatch(toggle());
  }
}
