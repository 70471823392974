import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryStatusRequest, SummaryRequest } from 'src/app/models/global.request';
import { Person } from 'src/app/models/Person';
import { Report } from 'src/app/models/Report';
import { ResearchCategory } from 'src/app/models/ResearchCategory';
import { ResearchHit } from 'src/app/models/ResearchHit';
import { AdminRequestsService } from 'src/app/services/admin-requests/admin-requests.service';
import { ListsService } from 'src/app/services/lists/lists.service';
import Formatter from 'src/app/utils/formatters';

@Component({
  selector: 'app-dash-report',
  templateUrl: './dash-report.component.html',
  styleUrls: ['./dash-report.component.scss']
})
export class DashboardReportComponent {

  @ViewChild('screen', { static: true }) screen: any;

  private attachmentInput!: ElementRef;

  @ViewChild('attachmentInput', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.attachmentInput = content;
    }
  }

  formatter!:Formatter;

  iframeUrl = '';

  searchForm = this.fb.group({
    search: ["", [Validators.required]],
  });

  toggleCompany:boolean = false;
  toggleMember:boolean = false;
  toggleCropEnable:boolean = false;
  toggleWebSearch: boolean = false;
  openHitModal: boolean = false;
  openCategorySeverityModal: boolean = false;
  openDeleteHitModal: boolean = false;
  openBiographyModal: boolean = false;

  previewIsRendered: boolean = false;

  sourceString = 'web';

  requestId = 0;
  targetId = 0;
  targetType = '';

  report!: Report;
  person!: Person;

  researchHits: ResearchHit[] = [];

  currentlyEditingHit: ResearchHit | null = null;
  deleteCandidateHit: ResearchHit | null = null;
  deleteCandidateHitIndex: number | null = null;

  researchHitForm!: FormGroup;

  biographyForm = this.fb.group({
    biography: ["", Validators.required],
    keywords: ["", Validators.required],
    urls: this.fb.array([
      this.fb.control('', [])
    ])
  });

  categorySeverityForm = this.fb.group({
    category: ["", Validators.required],
    severity: ["", Validators.required],
  });

  researchCategories: ResearchCategory[] = [];

  internationalDbs = [
    {
      url: "https://www.world-check.com/frontend/login/",
      name: "World Check"
    }, {
      url: "https://example.com/",
      name: "Database 2"
    }, {
      url: "https://example.com/",
      name: "Database 3"
    }, {
      url: "https://example.com/",
      name: "Database 4"
    }
  ];

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public translate: TranslateService,
    public listsService: ListsService,
    public adminRequestsService: AdminRequestsService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.formatter = new Formatter();

    this.route.params.subscribe(params => {
      this.requestId = params['id'];
      this.targetId = params['id_target'];
      this.targetType = params['target_type'];

      // get the request data
      this.adminRequestsService.getRequest(this.requestId).subscribe(res => {
        if (res.result && res.data) {
          this.report = this.formatter.formatResponseGetAdminRequest(res.data);

          if (this.targetType === 'person') {
            this.report.persons.forEach(p => {
              if (this.targetId == parseInt(p.id!)) {
                this.person = new Person(p.id, p.surname, p.name, p.gender, p.birth_date, p.fiscal_code, p.birth_place, p.residence, p.residence_number, p.residence_city, p.residence_province, p.nationality, p.request_id, p.summary, p.summary_links, p.summary_keywords);
                this.biographyForm.patchValue({
                  biography: this.person.summary,
                  keywords: this.person.summary_keywords
                });
                if (Array.isArray(this.person.summary_links)) {
                  this.person.summary_links?.forEach((url, index) => {
                    if (index == 0) {
                      this.biographyUrls.controls[index].patchValue(url);
                    } else {
                      this.biographyUrls.push(this.fb.control(url));
                    }
                  })
                }
              }
            })
          } else if (this.targetType === 'company' && this.report.company) {
            this.biographyForm.patchValue({
              biography: this.report.company.summary,
              keywords: this.report.company.summary_keywords
            });
            if (Array.isArray(this.report.company.summary_links)) {
              this.report.company.summary_links?.forEach((url, index) => {
                if (index == 0) {
                  this.biographyUrls.controls[index].patchValue(url);
                } else {
                  this.biographyUrls.push(this.fb.control(url));
                }
              })
            }
          }
        }
      })

      //  get the research hits (and the attachments) for the request and the person
      this.adminRequestsService.getResearchHits(this.requestId, this.targetType, this.targetId).subscribe(res => {
        if (res.result && res.data) {
          this.researchHits = this.researchHits.concat(this.formatter.formatGetResearchHits(res.data));
          this.researchHits.forEach(hit => {
            if (hit.attachments.length) {
              hit.attachments.forEach(att => {
                this.adminRequestsService.getResearchHitAttachment(att.id).subscribe(res => {
                  const fileType = this.formatter.formatMIMEType(att.filename, att.type);
                  const file = new File([res.body], att.filename, {type: fileType});
                  hit.attachment = file;
                  const blob = new Blob([res.body], {type: fileType});
                  hit.attachment_blob = blob;
                })
              })
            }
          });
        }
      });

      // get research categories, get the severities and create form
      this.listsService.getResearchCategories().subscribe(res => {
        if (res.result && res.data) {
          this.researchCategories = this.formatter.formatResponseGetResearchCategories(res.data);

          this.adminRequestsService.getCategoryStatuses(this.requestId, this.targetType, this.targetId).subscribe(res => {
            if (res.result && res.data) {
              res.data.forEach((sev: any) => {
                this.researchCategories.forEach(cat => {
                  if (cat.id === sev.be_research_category.id) {
                    cat.severity = sev.severity;
                  }
                })
              })
            }
          });

          let categoriesGroup: {[k: string]: any} = {};
          this.researchCategories.forEach(category => {
            categoriesGroup[category.key.replaceAll('-', '')] = new FormControl(false);
          });
          this.researchHitForm = this.fb.group({
            attachment: ["", []],
            source: ["", [Validators.required]],
            title: ["", [Validators.required]],
            url: ["", [Validators.required]],
            notes: [""],
            category: new FormGroup(categoriesGroup, c => {
              var atLeastOneChecked = false;
              Object.keys((c as FormGroup).controls).forEach(key => {
                const control = (c as FormGroup).controls[key];
                if (control.value === true) {
                  atLeastOneChecked = true;
                }
              })
              if (atLeastOneChecked) {
                return {};
              }
              return { error: true };
            }),
            severity: ["", [Validators.required]],
          });
        }
      });

    });
  }

  // NEW RESEARCH HIT: open modal with an empty form
  onClickNewResearchHit() {
    this.researchHitForm.reset();
    this.currentlyEditingHit = null;
    this.openHitModal = true;
    this.researchHitForm.controls['source'].setValue(this.sourceString);
  }

  // EDIT RESEARCH HIT: open modal with filled form
  onClickEditResearchHit(hit: ResearchHit) {
    this.researchHitForm.patchValue({
      title: hit.title,
      url: hit.url,
      notes: hit.notes,
      severity: hit.severity,
      source: hit.type,
      attachment: hit.attachment,
    });

    var currentCategories: {[k: string]: any} = {};
    this.researchCategories.forEach(category => {
      currentCategories[category.key.replaceAll('-', '')] = this.researchHitHasCategory(hit, category.key.replaceAll('-', ''));
    });
    this.researchHitForm.controls['category'].setValue(currentCategories);
    this.currentlyEditingHit = hit;
    this.openHitModal = true;

    this.changeDetector.detectChanges();
    if (this.attachmentInput && hit.attachment) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(hit.attachment);
      this.attachmentInput.nativeElement.files = dataTransfer.files;
    }
  }

  // SUBMIT RESEARCH HIT: save new research hit or update an existing one
  onSubmitSaveHit() {
    const research_hit_data = {
      person_id: this.targetType === 'person' ? +this.targetId : null,
      company_id: this.targetType === 'company' ? +this.targetId : null,
      title: this.researchHitForm.controls['title'].value,
      url: this.researchHitForm.controls['url'].value,
      notes: this.researchHitForm.controls['notes'].value,
      type: this.researchHitForm.controls['source'].value,
      severity: this.researchHitForm.controls['severity'].value,
      research_hit_categories: this.getSelectedCategories().map(c => c?.id)
    };
    const formData = new FormData();
    formData.append('research_hit', JSON.stringify(research_hit_data));
    if (this.researchHitForm.controls['attachment'].value) {
      formData.append('research_file', this.researchHitForm.controls['attachment'].value);
    }

    if (this.currentlyEditingHit) {
      // update existing research hit
      if (this.currentlyEditingHit.id) {
        const currentHitId = this.currentlyEditingHit.id;
        this.adminRequestsService.updateResearchHit(this.requestId, this.currentlyEditingHit.id, formData).subscribe(res => {
          if (res.result && res.data) {
            const editHit = this.formatter.formatResponseResearchHit(res.data);
            if (editHit.attachments.length) {
              editHit.attachments.forEach(att => {
                this.adminRequestsService.getResearchHitAttachment(att.id).subscribe(res => {
                  const fileType = this.formatter.formatMIMEType(att.filename, att.type);
                  const file = new File([res.body], att.filename, {type: fileType});
                  editHit.attachment = file;
                  const blob = new Blob([res.body], {type: fileType});
                  editHit.attachment_blob = blob;
                  this.researchHits = this.researchHits.map(obj => obj.id == currentHitId ? editHit : obj);
                })
              })
            } else {
              this.researchHits = this.researchHits.map(obj => obj.id == currentHitId ? editHit : obj);
            }
            this.toastr.success(this.translate.instant('editResearchHitSuccess'));
          }
        })
      }
    } else {
      // save new research hit
      this.adminRequestsService.saveResearchHit(this.requestId, formData).subscribe(res => {
        if (res.result && res.data) {
          const newHit = this.formatter.formatResponseResearchHit(res.data);
          if (newHit.attachments.length) {
            newHit.attachments.forEach(att => {
              this.adminRequestsService.getResearchHitAttachment(att.id).subscribe(res => {
                const fileType = this.formatter.formatMIMEType(att.filename, att.type);
                const file = new File([res.body], att.filename, {type: fileType});
                newHit.attachment = file;
                const blob = new Blob([res.body], {type: fileType});
                newHit.attachment_blob = blob;
                this.researchHits.push(newHit);
              })
            })
          } else {
            this.researchHits.push(newHit);
          }
          this.toastr.success(this.translate.instant('newResearchHitSuccess'));
        }
      });
    }

    this.updateCategorySeverityFromHit();
    this.researchHitForm.reset();
    this.openHitModal = false;
    this.currentlyEditingHit = null;
  }

  // START CHANGE CATEGORY SEVERITY: open modal and fill form with the current situation for the selected category
  onClickChangeCategorySeverity(category: any) {
    if (this.researchHits.length > 0) {
      this.categorySeverityForm.setValue({
        category: category.label,
        severity: category.severity,
      });
      this.openCategorySeverityModal = true;
    }
  }

  onClickDeleteHit() {
    if (this.deleteCandidateHit != null && this.deleteCandidateHitIndex !== null) {
      this.adminRequestsService.deleteResearchHit(this.requestId, this.deleteCandidateHit.id!).subscribe(res => {
        if (res.result) {
          this.researchHits.splice(this.deleteCandidateHitIndex!, 1);
          this.openDeleteHitModal = false;
          this.toastr.success(this.translate.instant('deleteSuccessful'));
        }
      })
    }
  }

  // SUBMIT CHANGE TYPE SEVERITY: update the severity of the selected category
  onSubmitChangeCategorySeverity() {
    const category: ResearchCategory | undefined = this.researchCategories.find(obj => obj.label === this.categorySeverityForm.controls.category.value);
    if (category != null) {
      category.severity = this.categorySeverityForm.controls.severity.value ? this.categorySeverityForm.controls.severity.value : '';
    }
    this.openCategorySeverityModal = false;
    this.categorySeverityForm.reset();

    const params = new CategoryStatusRequest(
      this.targetType,
      +this.targetId,
      category?.id!,
      category?.severity!,
    );
    this.adminRequestsService.setCategoryStatus(this.requestId, params).subscribe(res => {
      if (res.result && res.data) {
        this.toastr.success(this.translate.instant('successSeverity'));
      }
    })
  }

  // Return the categories selected in the form as a string
  getSelectedCategories(): (ResearchCategory | undefined)[] {
    var _categories: (ResearchCategory | undefined)[] = [];
    Object.keys((this.researchHitForm.get('category') as FormGroup)['controls']).forEach(key => {
      const control = ((this.researchHitForm.get('category') as FormGroup)['controls'] as any)[key];
      if (control.value === true) {
        _categories.push(this.getCategoryFromFormControlName(key));
      }
    });
    return _categories;
  }

  // Get the category given the name of the corresponding form control
  getCategoryFromFormControlName(formControlName: string) {
    return this.researchCategories.find(obj => obj.key.replaceAll('-', '') === formControlName);
  }

  // Check if a research hit has a specific category given its form control name
  researchHitHasCategory(hit: ResearchHit, formControlName: string) {
    const category = this.getCategoryFromFormControlName(formControlName);
    if (hit.research_hit_categories.includes(category?.id!)) {
      return true;
    } else {
      return false;
    }
  }

  // Update the severity of each category when a research hit is created of edited
  updateCategorySeverityFromHit() {
    const severity = this.researchHitForm.controls['severity'].value;
    Object.keys((this.researchHitForm.get('category') as FormGroup)['controls']).forEach(key => {
      const control = ((this.researchHitForm.get('category') as FormGroup)['controls'] as any)[key];
      if (control.value === true) {
        const category = this.getCategoryFromFormControlName(key);
        if (category != null) {
          switch (severity) {
            case 'high':
              category.severity = severity;
              break;
            case 'medium':
              category.severity = category.severity !== 'high' ? severity : category.severity;
              break;
            default:
              break;
          }

          const params = new CategoryStatusRequest(this.targetType, this.targetId, category.id, category.severity);
          this.adminRequestsService.setCategoryStatus(this.requestId, params).subscribe();
        }
      }
    })
  }

  // Generate report
  onClickGenerateReport() {
    this.adminRequestsService.generateReport(this.requestId, this.targetType, this.targetId).subscribe(res => {
      if (res.headers.get('Content-Type')?.includes('application/pdf')) {
        const file = new Blob([res.body], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        this.toastr.success(this.translate.instant('reportCreated'));
      }
    });
  }

  onClickRenderPreview() {
    this.adminRequestsService.generateReport(this.requestId, this.targetType, this.targetId).subscribe(res => {
      if (res.headers.get('Content-Type')?.includes('application/pdf')) {
        const file = new Blob([res.body], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        this.iframeUrl = fileURL;
        this.previewIsRendered = true;
      }
    });
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.researchHitForm.patchValue({
        attachment: file
      });
    }
  }

  get biographyUrls() {
    return this.biographyForm.get('urls') as FormArray;
  }

  onClickAddUrl() {
    this.biographyUrls.push(this.fb.control(''));
  }

  onClickRemoveUrl(index: number) {
    this.biographyUrls.removeAt(index);
  }

  onSubmitSaveBiography() {
    var urls: string[] = [];
    this.biographyUrls.controls.forEach(url => {
      urls.push(url.value);
    });
    const params = new SummaryRequest(
      this.targetType,
      +this.targetId,
      this.biographyForm.get('biography')?.value!,
      urls.join(', '),
      this.biographyForm.get('keywords')?.value!
    );
    this.openBiographyModal = false;
    this.adminRequestsService.setSummary(this.requestId, params).subscribe(res => {
      if (res.result && res.data) {
        this.toastr.success(this.translate.instant('successBiography'));
      }
    })
  }

  onSubmitSearch() {
    this.previewIsRendered = false;
    const searchValue = this.searchForm.get('search')?.value || '';
    if (this.isURL(searchValue)) {
      this.iframeUrl = searchValue;
    } else {
      this.iframeUrl = 'https://www.google.com/search?igu=1&q=' + encodeURIComponent(searchValue);
    }
  }

  isURL(str: string) {
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if(!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  onChangeGoToDatabase(db_url: string) {
    if (db_url !== '') {
      this.sourceString = 'db';
      window.open(
        db_url,
        '_blank'
      );
    } else {
      this.sourceString = 'web';
    }
  }

  onClickOpenAttachment(attachment: Blob | null) {
    if (attachment) {
      const fileURL = URL.createObjectURL(attachment);
      window.open(fileURL, '_blank');
    }
  }

  onClickOpenGoogle() {
    window.open('https://www.google.com/', '_blank');
  }

  // Get the string of research categories given an array of ResearchCategories
  getCategoriesLabel(categories: (ResearchCategory | undefined)[]) {
    return categories.map(c => c?.label).join(' | ');
  }

  getDateForDisplay(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '/');
    else
      return '';
  }
}
