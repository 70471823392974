import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanDeactivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";

import { ROUTES } from '../../utils/constants'


import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  constructor(
      private authService: AuthenticationService,
      private router: Router,
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
      let url: string = state.url;
      return this.checkUserLogin(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.getAuthStatus()) {
      const userRole = this.authService.getRole();
      if (route.data["role"] && route.data["role"].indexOf(userRole) === -1) {
        if (userRole === "customer") {
          this.router.navigate(['/home']);
        } else if (userRole === "administrator" || userRole === "operator") {
          this.router.navigate(['/dashboard']);
        }
        return false;
      }
      return true;
    }
    this.router.navigateByUrl('/login?action=logout');
    return false;



  }
}
