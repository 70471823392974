<app-header>
</app-header>
<div>
  <div>
    <app-notifications>
    </app-notifications>
    <div class="container">
        <router-outlet></router-outlet>
    </div>
  </div>
</div>
