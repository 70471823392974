<div id="login">
  <ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'checking'">
      <div class="auth-message">{{ 'auth_checking' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'denied'" class="auth-message">
      <div class="auth-message">{{ 'auth_failure' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'logout'" class="auth-message">
      <div class="auth-message">{{ 'auth_logout' | translate }}</div>
    </ng-container>
  </ng-container>
</div>
