
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from '../../services/auth/authorization.guards';
import { UserAreaLandingComponent } from './components/userarea-landing/userarea-landing.component';
import { UserAreaRequestComponent } from './components/userarea-request/userarea-request.component';
import { UserAreaUploadComponent } from './components/userarea-upload/userarea-upload.component';

import { AuthGuard } from 'src/app/services/auth/authorization.guards';


// , canActivate: [AuthGuard]
const routes: Routes = [
  {path: 'home', component: UserAreaLandingComponent, canActivate: [AuthGuard],
  data: {
    role: 'customer'
  }},
  {path: 'new-request', component: UserAreaRequestComponent, canActivate: [AuthGuard],
  data: {
    role: 'customer'
  }},
  {path: 'upload', component: UserAreaUploadComponent, canActivate: [AuthGuard],
  data: {
    role: 'customer'
  }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserAreaRoutingModule { }
