import { Component, OnDestroy } from '@angular/core';
//Translation
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'hintegrity-frontend';

  functionListener: any;

  constructor(
    public translate: TranslateService,
  ){
    translate.addLangs(['en','it']);
    translate.setDefaultLang('it');
    translate.use('it');

    this.functionListener = this.authStateListener.bind(this);
    window.addEventListener('storage', this.functionListener);
  }

  authStateListener(event: any) {
    if (event.key === 'state') {
      window.location.reload();
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.functionListener);
  }
}
