//Angular
import { Component, HostListener } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Service } from "src/app/models/Service";
import { ListsService } from "src/app/services/lists/lists.service";
import { UserRequestsService } from "src/app/services/user-requests/user-requests.service";

//Constants
import { ROUTES } from 'src/app/utils/constants';
import Formatter from "src/app/utils/formatters";


@Component({
  selector: "app-userarea-upload",
  templateUrl: "./userarea-upload.component.html",
  styleUrls: ["./userarea-upload.component.scss"],

})

export class UserAreaUploadComponent {
  cursor!: string;
  formatter!: Formatter;
  showModalUpload: boolean = false;

  showConfirm = false;
  confirmData: any = null;

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.showModalUpload= true;
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    // this.showModalUpload= false;
  }
  ROUTES = ROUTES;

  services: Service[] = [];

  uploadForm = this.fb.group({
    excelFile: [null, [Validators.required]],
    isMaxSize: [false],
    service: [null, [Validators.required]],
  });
  fileName = null;
  showModalSubmitted = false;

  constructor(
    public fb: FormBuilder,
    public listsService: ListsService,
    public userServices: UserRequestsService,
    public translate: TranslateService,
    public toastr: ToastrService,
  ) {
    this.formatter = new Formatter();
    this.listsService.getServices().subscribe(res => {
      if (res.result && res.data) {
        this.services = this.formatter.formatResponseGetServices(res.data);
      }
    })
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.fileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.uploadForm.controls['excelFile'].setValue(event.target.files[0]);
      const fileSizeInMB = event.target.files[0].size / (1024 * 1024);
      if (fileSizeInMB < 32) {
        this.uploadForm.controls['isMaxSize'].setValue(true);
      } else {
        this.uploadForm.controls['isMaxSize'].setValue(false);
      }
    }
    this.showModalUpload = false;
  }

  onSubmitUpload() {
    const request = new FormData();
    request.append('service_id', this.uploadForm.get('service')?.value!);
    request.append('requests_file', this.uploadForm.get('excelFile')?.value!);
    this.userServices.massInsert(request).subscribe(res => {
      if (res.result && res.data) {
        if (res.data.requests.length) {
          this.confirmData = res.data;
          this.showConfirm = true;
        } else {
          this.toastr.error(this.translate.instant('noRequestsFound'));
        }
      } else {
        this.toastr.error(this.translate.instant('uploadError'));
      }
    });
  }

  onClickConfirmUpload() {
    this.userServices.massInsertConfirm(this.confirmData).subscribe(conf => {
      if (conf.result) {
        this.showModalSubmitted = true;
      } else {
        this.toastr.error(this.translate.instant('uploadError'));
      }
    })
  }

  getServiceDescription(service: string): string {
    return this.translate.instant('description' + service.replaceAll(' ', ''));
  }

  getDateForDisplay(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '/');
    else
      return '';
  }

  get isMaxSize() {
    return this.uploadForm.get("isMaxSize");
  }
}
