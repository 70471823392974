import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  constructor(
    private http: HttpClient,
  ) { }

  public getServices(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}services`);
  }

  public getResearchCategories(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}research-categories`);
  }

  public getOperators(): Observable<any> {
    return this.http.get<any[]>(`${environment.commerceUrl}wp-json/v1/operators`);
  }

}
