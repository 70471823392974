import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CreateUserRequestRequest, PaginationParams } from 'src/app/models/global.request';

@Injectable({
  providedIn: 'root'
})
export class UserRequestsService {

  constructor(
    private http: HttpClient,
  ) { }

  public getRequestsList(params: PaginationParams, filter: string | null): Observable<any> {
    if (filter) {
      return this.http.get<any[]>(`${environment.apiUrl}user/requests`, {params: {start: params.start, limit: params.limit, status: filter}});
    } else {
      return this.http.get<any[]>(`${environment.apiUrl}user/requests`, {params: {start: params.start, limit: params.limit}});
    }
  }

  public sendRequest(request: CreateUserRequestRequest): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}user/requests`, request);
  }

  public downloadReport(id: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}user/requests/${id}/generateReport`, null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      observe: "response",
      responseType: "blob",
    });
  }

  public massInsert(request: FormData): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}user/requests/import`, request);
  }

  public massInsertConfirm(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}user/requests/import-confirm`, data);
  }

}
