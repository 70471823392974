export class ResearchCategory {
  id: number;
  key: string;
  label: string;
  visible: boolean;
  severity: string;
  expandResearchHits: boolean = false;
  constructor(id: number, key: string, label: string, visible: boolean, severity: string = 'low') {
    this.id = id;
    this.key = key;
    this.label = label;
    this.visible = visible;
    this.severity = severity;
  }
}
