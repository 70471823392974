//Angular
import { Component } from "@angular/core";
import { saveAs } from 'file-saver';

import { FormBuilder } from "@angular/forms";

import Formatter  from "../../../../utils/formatters";

import { ROUTES } from '../../../../utils/constants'
import { Report } from "src/app/models/Report";
import { UserRequestsService } from "src/app/services/user-requests/user-requests.service";
import { PaginationParams } from "src/app/models/global.request";

@Component({
  selector: "app-userarea-landing",
  templateUrl: "./userarea-landing.component.html",
  styleUrls: ["./userarea-landing.component.scss"],

})
export class UserAreaLandingComponent {

  formatter!:Formatter;
  public utils = Formatter;
  ROUTES = ROUTES;

  start = 0;
  limit = 10;

  showLoadMore = false;

  filter: string = 'all';

  reports: Report[] = [];

  //Constructor
  constructor(
    public fb: FormBuilder,
    public userRequestsService: UserRequestsService,
  ) {
    this.formatter = new Formatter();
    const params = new PaginationParams(this.start, this.limit);
    this.userRequestsService.getRequestsList(params, null).subscribe(res => {
      if (res.result) {
        this.reports = this.formatter.formatResponseGetUserRequests(res.data);

        if (this.reports.length < res.data.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  // On Component Initialized
  ngOnInit(): void {

  }

  onClickLoadMore() {
    var filterParam = null;
    if (this.filter !== 'all') {
      filterParam = this.filter;
    }
    const params = new PaginationParams(this.start, this.limit);
    this.userRequestsService.getRequestsList(params, filterParam).subscribe(res => {
      if (res.result) {
        this.reports = this.reports.concat(this.formatter.formatResponseGetUserRequests(res.data));

        if (this.reports.length < res.data.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  onChangeFilterRequests(filter: any) {
    var filterParam = null;
    if (filter !== 'all') {
      filterParam = filter;
    }
    this.start = 0;

    const params = new PaginationParams(this.start, this.limit);
    this.userRequestsService.getRequestsList(params, filterParam).subscribe(res => {
      if (res.result) {
        this.reports = this.formatter.formatResponseGetUserRequests(res.data);

        if (this.reports.length < res.data.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  onClickDownloadReport(report: Report) {
    this.userRequestsService.downloadReport(report.id).subscribe(res => {
      if (res.headers.get('Content-Type')?.includes('application/pdf')) {
        var fileName = this.getDateForReportDownload(report.created_at) + ' ' + (report.company ? report.company.business_name : report.persons[0].name + ' ' + report.persons[0].surname);
        const file = new Blob([res.body], {type: 'application/pdf'});
        saveAs(file, fileName);
      }
    })
  }

  // returns date as YYYY/MM/DD
  getDateForDisplay(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '/');
    else
      return '';
  }

  // returns date as YYYY_MM_DD
  getDateForReportDownload(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '_');
    else
      return '';
  }

}

