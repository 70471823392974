import { Company } from "./Company";
import { Person } from "./Person";
import { Service } from "./Service";
import { User } from "./User";

export class Report {
  public id: number;
  public status: string;
  public priority: string;
  public created_at: string;
  public download_date: string | null;
  public expiry_date: string;
  public operator_id: string | null;
  public service_id: number;
  public updated_at: string | null;
  public completed_at: string | null;
  public user_id: number;
  public check_status: string;
  public persons: Person[];
  public company: Company | null;
  public service: Service | null;
  public customer: User | null;
  public operator: User | null;
  public is_expanded: boolean = false;

  constructor (id: number, status: string, priority: string = 'medium', created_at: string, download_date: string | null, expiry_date: string, completed_at: string | null, operator_id: string | null, service_id: number, updated_at: string | null, user_id: number, persons: Person[], company: Company | null, service: Service | null = null, customer: User | null = null, operator: User | null = null) {
    this.id = id;
    this.status = status;
    this.priority = priority;
    this.created_at = created_at;
    this.download_date = download_date;
    this.expiry_date = expiry_date;
    this.completed_at = completed_at;
    this.operator_id = operator_id;
    this.service_id = service_id;
    this.updated_at = updated_at;
    this.user_id = user_id;
    this.check_status = download_date ? 'checked' : 'not-checked';
    this.persons = persons;
    this.company = company;
    this.service = service;
    this.customer = customer;
    this.operator = operator;
  }
}
