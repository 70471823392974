import { NotificationsReducer } from './notifications/notifications.reducer';
import { AuthenticationReducer } from  './auth/authentication.reducer';
import { CreditsReducer } from './credits/credits.reducer';


// import { RouterReducer } from  './router/router.reducer';


import { MetaReducer } from "@ngrx/store";
import { HydrationMetaReducer } from "./hydratation/hydratation.reducer";


export const reducers= {
  authState: AuthenticationReducer,
  notificationsState: NotificationsReducer,
  creditsState: CreditsReducer,
};
export const metaReducers: MetaReducer[] = [HydrationMetaReducer];


