<div id="notifications" *ngIf="(notificationsState$ | async); let notificationsState;" [ngClass]="(notificationsState?.isOpenNotifications)? 'show' : 'hide'">
  <div class="notifications-wrapper" [ngClass]="(notificationsState?.isOpenNotifications)? 'show' : 'hide'"  >
    <div class="actions" >
        <ng-container *ngIf="notificationsState.unreadCount > 0; else noUnread">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.39062 11.125C4.38958 10.384 4.53514 9.65017 4.81892 8.96572C5.1027 8.28126 5.5191 7.65971 6.04412 7.13686C6.56914 6.61402 7.19242 6.20022 7.87805 5.91928C8.56368 5.63835 9.29812 5.49584 10.0391 5.49996C13.1328 5.5234 15.6094 8.09371 15.6094 11.1953V11.75C15.6094 14.5468 16.1953 16.1718 16.7109 17.0625C16.7657 17.1573 16.7946 17.2649 16.7947 17.3744C16.7948 17.4839 16.7661 17.5916 16.7115 17.6865C16.6569 17.7815 16.5783 17.8604 16.4836 17.9154C16.3889 17.9704 16.2814 17.9996 16.1719 18H3.82812C3.7186 17.9996 3.6111 17.9704 3.51639 17.9154C3.42169 17.8604 3.3431 17.7815 3.28851 17.6865C3.23391 17.5916 3.20523 17.4839 3.20532 17.3744C3.20542 17.2649 3.2343 17.1573 3.28906 17.0625C3.80468 16.1718 4.39062 14.5468 4.39062 11.75V11.125Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 18V18.625C7.5 19.288 7.76339 19.9239 8.23223 20.3928C8.70107 20.8616 9.33696 21.125 10 21.125C10.663 21.125 11.2989 20.8616 11.7678 20.3928C12.2366 19.9239 12.5 19.288 12.5 18.625V18" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="20" cy="3" r="3" fill="#C90000"/>
          </svg>
        </ng-container>
        <ng-template #noUnread>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.39062 8.12496C4.38958 7.38401 4.53514 6.65017 4.81892 5.96572C5.1027 5.28126 5.5191 4.65971 6.04412 4.13686C6.56914 3.61402 7.19242 3.20022 7.87805 2.91928C8.56368 2.63835 9.29812 2.49584 10.0391 2.49996C13.1328 2.5234 15.6094 5.09371 15.6094 8.19528V8.74996C15.6094 11.5468 16.1953 13.1718 16.7109 14.0625C16.7657 14.1573 16.7946 14.2649 16.7947 14.3744C16.7948 14.4839 16.7661 14.5916 16.7115 14.6865C16.6569 14.7815 16.5783 14.8604 16.4836 14.9154C16.3889 14.9704 16.2814 14.9996 16.1719 15H3.82812C3.7186 14.9996 3.6111 14.9704 3.51639 14.9154C3.42169 14.8604 3.3431 14.7815 3.28851 14.6865C3.23391 14.5916 3.20523 14.4839 3.20532 14.3744C3.20542 14.2649 3.2343 14.1573 3.28906 14.0625C3.80468 13.1718 4.39062 11.5468 4.39062 8.74996V8.12496Z" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 15V15.625C7.5 16.288 7.76339 16.9239 8.23223 17.3928C8.70107 17.8616 9.33696 18.125 10 18.125C10.663 18.125 11.2989 17.8616 11.7678 17.3928C12.2366 16.9239 12.5 16.288 12.5 15.625V15" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </ng-template>
        <div>
          <svg (click)="toggle()" style="cursor:pointer;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.625 4.375L4.375 15.625" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.625 15.625L4.375 4.375" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
    </div>
    <div class="content">
      <div class="main-title">
        {{ 'notifications' | translate }}
      </div>

      <form class="filter-notifications" [formGroup]="filtersForm" (ngSubmit)="onSubmitFilterList()">
        <div class="filter-title">{{ 'filterBy' | translate }}:</div>
        <div *ngIf="(authState$ | async); let authState;" class="filter-container">
          <div class="filter-wrapper" *ngIf="(authState?.user?.role ==='administrator' || authState?.user?.role ==='operator')">
            <select class="form-control" formControlName="type" (change)="onSubmitFilterList()">
              <option value="" disabled selected hidden>{{ 'type' | translate }}</option>
              <option value="NEW_REQUEST" *ngIf="authState?.user?.role ==='administrator'">{{ 'newRequestsLabel' | translate }}</option>
              <option value="ASSIGNEMENT" *ngIf="authState?.user?.role ==='operator'">{{ 'assignmentLabel' | translate }}</option>
              <option value="PRIORITY_CHANGE,STATUS_CHANGE,EXPIRY_DATE_CHANGE">{{ 'variationsLabel' | translate }}</option>
              <option value="">{{ 'expirationLabel' | translate }}</option>
            </select>
          </div>
          <div class="filter-wrapper">
            <div class="checkbox-wrapper">
              <input type="checkbox" id="unread" formControlName="unread" (change)="onSubmitFilterList()">
              <label for="unread">{{ 'toRead' | translate }}</label>
            </div>
          </div>
        </div>
      </form>

      <ng-container *ngIf="notifications.length === 0; else showNotifications">
        <div class="empty">{{ 'noNotifications' | translate }}</div>
      </ng-container>
      <ng-template #showNotifications>
        <div class="notifications-list" *ngIf="(authState$ | async); let authState;">
          <div class="notification" [ngClass]="n.is_read === 1 ? 'read' : ''" *ngFor="let n of notifications">
            <ng-container [ngSwitch]="n.type">
              <ng-container *ngSwitchCase="'NEW_REQUEST'">
                <div class="label">
                  {{ 'newRequestLabel' | translate }}
                  <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                    {{ 'archive' | translate }}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                    </svg>
                  </button>
                </div>
                <div class="left">
                  <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                    <div class="circle"></div>
                    {{ 'markAsRead' | translate }}
                  </button>
                  <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                </div>
                <div class="right">
                  <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                  <div class="message"><span class="desc">{{ 'notif_new_request' | translate }}</span></div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'ASSIGNEMENT'">
                <div class="label">
                  {{ 'assignmentLabel' | translate }}
                  <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                    {{ 'archive' | translate }}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                    </svg>
                  </button>
                </div>
                <div class="left">
                  <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                    <div class="circle"></div>
                    {{ 'markAsRead' | translate }}
                  </button>
                  <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                </div>
                <div class="right">
                  <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                  <div class="message"><span class="desc">{{ 'notif_assignment' | translate }}</span></div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'status' | translate }}:</span> {{ n.request.status | translate }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'priority' | translate }}:</span> {{ n.request.priority + 'F' | translate }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'deadline' | translate }}:</span> {{ getDateForDisplay(n.request.expiry_date) }}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'PRIORITY_CHANGE'">
                <div class="label">
                  {{ 'variationsLabel' | translate }}
                  <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                    {{ 'archive' | translate }}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                    </svg>
                  </button>
                </div>
                <div class="left">
                  <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                    <div class="circle"></div>
                    {{ 'markAsRead' | translate }}
                  </button>
                  <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                </div>
                <div class="right">
                  <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                  <div class="message"><span class="desc">{{ 'notif_change_priority' | translate }}:</span> {{ n.extra.priority + 'F' | translate }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'status' | translate }}:</span> {{ n.request.status | translate }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'deadline' | translate }}:</span> {{ getDateForDisplay(n.request.expiry_date) }}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'STATUS_CHANGE'">
                <ng-container *ngIf="authState?.user?.role ==='customer'; else statusChangedOperAdmin">
                  <div class="label">
                    {{ n.extra.status | translate }}
                    <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                      {{ 'archive' | translate }}
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                      </svg>
                    </button>
                  </div>
                  <div class="left">
                    <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                      <div class="circle"></div>
                      {{ 'markAsRead' | translate }}
                    </button>
                    <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                  </div>
                  <div class="right">
                    <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                    <div class="message"><span class="desc">{{ 'notif_change_status_customer' | translate }} {{ n.extra.status + 'F' | translate }}</span></div>
                  </div>
                </ng-container>
                <ng-template #statusChangedOperAdmin>
                  <div class="label">
                    {{ 'variationsLabel' | translate }}
                    <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                      {{ 'archive' | translate }}
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                      </svg>
                    </button>
                  </div>
                  <div class="left">
                    <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                      <div class="circle"></div>
                      {{ 'markAsRead' | translate }}
                    </button>
                    <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                  </div>
                  <div class="right">
                    <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                    <div class="message"><span class="desc">{{ 'notif_change_status' | translate }}:</span> {{ n.extra.status | translate }}</div>
                    <div class="message" *ngIf="n.request"><span class="desc">{{ 'priority' | translate }}:</span> {{ n.request.priority + 'F' | translate }}</div>
                    <div class="message" *ngIf="n.request"><span class="desc">{{ 'deadline' | translate }}:</span> {{ getDateForDisplay(n.request.expiry_date) }}</div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container *ngSwitchCase="'EXPIRY_DATE_CHANGE'">
                <div class="label">
                  {{ 'variationsLabel' | translate }}
                  <button class="base--button archive" (click)="showConfirmArchive = true; archiveCandidate = n;">
                    {{ 'archive' | translate }}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3125 15.1875H1.6875C1.53832 15.1875 1.39524 15.1282 1.28975 15.0227C1.18426 14.9173 1.125 14.7742 1.125 14.625V6.75C1.125 6.60082 1.18426 6.45774 1.28975 6.35225C1.39524 6.24676 1.53832 6.1875 1.6875 6.1875H16.3125C16.4617 6.1875 16.6048 6.24676 16.7102 6.35225C16.8157 6.45774 16.875 6.60082 16.875 6.75V14.625C16.875 14.7742 16.8157 14.9173 16.7102 15.0227C16.6048 15.1282 16.4617 15.1875 16.3125 15.1875ZM1.6875 16.3125C1.23995 16.3125 0.810725 16.1347 0.494257 15.8182C0.17779 15.5018 0 15.0726 0 14.625L0 6.75C0 6.30245 0.17779 5.87322 0.494257 5.55676C0.810725 5.24029 1.23995 5.0625 1.6875 5.0625H16.3125C16.7601 5.0625 17.1893 5.24029 17.5057 5.55676C17.8222 5.87322 18 6.30245 18 6.75V14.625C18 15.0726 17.8222 15.5018 17.5057 15.8182C17.1893 16.1347 16.7601 16.3125 16.3125 16.3125H1.6875ZM2.25 3.375C2.25 3.52418 2.30926 3.66726 2.41475 3.77275C2.52024 3.87824 2.66332 3.9375 2.8125 3.9375H15.1875C15.3367 3.9375 15.4798 3.87824 15.5852 3.77275C15.6907 3.66726 15.75 3.52418 15.75 3.375C15.75 3.22582 15.6907 3.08274 15.5852 2.97725C15.4798 2.87176 15.3367 2.8125 15.1875 2.8125H2.8125C2.66332 2.8125 2.52024 2.87176 2.41475 2.97725C2.30926 3.08274 2.25 3.22582 2.25 3.375ZM4.5 1.125C4.5 1.27418 4.55926 1.41726 4.66475 1.52275C4.77024 1.62824 4.91332 1.6875 5.0625 1.6875H12.9375C13.0867 1.6875 13.2298 1.62824 13.3352 1.52275C13.4407 1.41726 13.5 1.27418 13.5 1.125C13.5 0.975816 13.4407 0.832742 13.3352 0.727252C13.2298 0.621763 13.0867 0.5625 12.9375 0.5625H5.0625C4.91332 0.5625 4.77024 0.621763 4.66475 0.727252C4.55926 0.832742 4.5 0.975816 4.5 1.125Z" fill="#4D4D4D"/>
                    </svg>
                  </button>
                </div>
                <div class="left">
                  <button class="base--button mark-as-read" [ngClass]="n.is_read === 1 ? 'done' : ''" (click)="onClickMarkAsRead(n)">
                    <div class="circle"></div>
                    {{ 'markAsRead' | translate }}
                  </button>
                  <div class="time-since">{{ n.created_at | timeSince:translate.currentLang }}</div>
                </div>
                <div class="right">
                  <div class="request-ref">{{ 'request' | translate }} #{{ n.request_id }}</div>
                  <div class="message"><span class="desc">{{ 'notif_change_expiry' | translate }}:</span> {{ n.extra.expiry_date }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'priority' | translate }}:</span> {{ n.request.priority + 'F' | translate }}</div>
                  <div class="message" *ngIf="n.request"><span class="desc">{{ 'status' | translate }}:</span> {{ n.request.status | translate }}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <button *ngIf="showLoadMore" (click)="onClickLoadMore()" class="base--button load-more">{{ 'loadMore' | translate }}</button>
      </ng-template>
    </div>
  </div>
</div>

<ng-container *ngIf="showConfirmArchive">
  <div class="backdrop-modal" (click)="showConfirmArchive = false; archiveCandidate = null;"></div>
  <div class="custom-modal">
    <p class="message">{{ 'archiveNotificationMessage' | translate }}</p>
    <div class="btn-group center">
      <button class="base--button btn-bg-light" type="submit" (click)="showConfirmArchive = false; archiveCandidate = null;">{{ 'discard' | translate }}</button>
      <button class="base--button btn-bg-dark" type="submit" (click)="onClickArchive(archiveCandidate)" *ngIf="archiveCandidate">{{ 'archive' | translate }}</button>
    </div>
  </div>
</ng-container>
