import { NotificationsState } from '../../../stores/notifications/notifications.reducer';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, ElementRef, HostListener, Input } from '@angular/core';


//Actions
import { toggle, unread } from '../../../stores/notifications/notifications.actions';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { AuthState } from 'src/app/stores/auth/authentication.reducer';
import { PaginationParams } from 'src/app/models/global.request';
import { Notification } from 'src/app/models/Notification';
import Formatter from 'src/app/utils/formatters';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent {

  start = 0;
  limit = 3;
  total = 0;
  showLoadMore = false;

  showConfirmArchive = false;
  archiveCandidate: Notification | null = null;

  notifications: Notification[] = [];

  authState$: Observable<AuthState>;
  notificationsState$: Observable<NotificationsState>;
  isOpenNotifications = false;
  formatter!: Formatter;

  filters: any[] = [];
  filtersForm = this.fb.group({
    type: [""],
    unread: [""],
  });

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(this.eRef.nativeElement.contains(event.target)) {
    } else {
      if(this.isOpenNotifications){
        // this.store.dispatch(close());
      }
    }
  }

  constructor(
    public fb: FormBuilder,
    private eRef: ElementRef,
    public translate: TranslateService,
    private notificationsService: NotificationsService,
    private store: Store<{ authState: AuthState, notificationsState: NotificationsState }>
    )
  {
    this.formatter = new Formatter();
    this.authState$ = store.select('authState');
    this.notificationsState$ = store.select('notificationsState');
  }

  ngOnInit(): void {
    this.notificationsState$.subscribe(notificationsS => {
      this.isOpenNotifications = notificationsS.isOpenNotifications;
    });

    this.authState$.subscribe(authS => {
      if (authS.isAuthenticated) {
        const params = new PaginationParams(this.start, this.limit);
        this.notificationsService.getNotifications(params).subscribe(res => {
          if (res.result) {
            this.notifications = this.formatter.formatNotifications(res.data);
            this.total = res.data.total;

            if (this.notifications.length !== 0 && this.notifications.length < this.total) {
              this.showLoadMore = true;
              this.start += this.limit;
            } else {
              this.showLoadMore = false;
            }
          }
        });

        this.notificationsService.getNotificationsUnread().subscribe(res => {
          if (res.result) {
            this.store.dispatch(unread({count: res.data}));
          }
        });
      }
    });
  }
  toggle() {
    this.store.dispatch(toggle());
  }

  onClickMarkAsRead(notification: Notification) {
    var markAsRead = 1;
    if (notification.is_read === 1) {
      markAsRead = 0;
    }
    this.notificationsService.readNotification(notification.id, markAsRead).subscribe(res => {
      this.notificationsService.getNotificationsUnread().subscribe(res => {
        notification.is_read = markAsRead;
        this.store.dispatch(unread({count: res.data}));
      });
    });
  }

  onClickArchive(notification: Notification) {
    this.notificationsService.archiveNotification(notification.id).subscribe(res => {
      if (res.result) {
        this.notifications = this.notifications.filter(n => n.id !== notification.id);
        this.showConfirmArchive = false;
        this.archiveCandidate = null;
        this.total -= 1;
        if (this.notifications.length == this.total) {
          this.showLoadMore = false;
        }

        this.notificationsService.getNotificationsUnread().subscribe(res => {
          this.store.dispatch(unread({count: res.data}));
        });
      }
    })
  }

  onClickLoadMore() {
    const params = new PaginationParams(this.start, this.limit, '', null, this.filters);
    this.notificationsService.getNotifications(params).subscribe(res => {
      if (res.result) {
        this.notifications = this.notifications.concat(this.formatter.formatNotifications(res.data));
        this.total = res.data.total;

        if (this.notifications.length < this.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  onSubmitFilterList() {
    this.filters = [];
    Object.keys(this.filtersForm.controls).forEach(key => {
      const control = this.filtersForm.get(key) as FormGroup;
      if (control.value) {
        this.filters.push({key: key, value: control.value});
      }
    });
    const params = new PaginationParams(0, this.limit, '', null, this.filters);
    this.notificationsService.getNotifications(params).subscribe(res => {
      if (res.result) {
        this.notifications = this.formatter.formatNotifications(res.data);
        this.total = res.data.total;

        if (this.notifications.length < this.total) {
          this.showLoadMore = true;
          this.start = this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  getDateForDisplay(date: string | null): string {
    if (date)
      return date.split('T')[0];
    else
      return '';
  }
}



