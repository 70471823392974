import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: 'timeSince' })
export class TimeSincePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(date_string: any, ...args: any[]) {
    const date = new Date(date_string);

    var seconds = Math.floor(((new Date()).getTime() - date.getTime()) / 1000);
    var interval = seconds / 31536000;

    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_year');
      } else {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_years');
      }
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_month');
      } else {
      return Math.floor(interval) + ' ' + this.translate.instant('ago_months');
      }
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_day');
      } else {
      return Math.floor(interval) + ' ' + this.translate.instant('ago_days');
      }
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_hour');
      } else {
      return Math.floor(interval) + ' ' + this.translate.instant('ago_hours');
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_minute');
      } else {
        return Math.floor(interval) + ' ' + this.translate.instant('ago_minutes');
      }
    }

    if (Math.floor(interval) == 1) {
      return Math.floor(seconds) + ' ' + this.translate.instant('ago_second');
    } else {
      return Math.floor(seconds) + ' ' + this.translate.instant('ago_seconds');
    }
  }
}

@Pipe({
  name: 'isFilterPresent',
})
export class IsFilterPresentPipe implements PipeTransform {
  transform(filters: any[], prop: string): boolean {
    return filters.filter(f => f.key === prop).length ? true : false;
  }
}
