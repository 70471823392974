//Angular
import { Component} from "@angular/core";
import { ROUTES } from "src/app/utils/constants";
import { FormBuilder, FormGroup } from "@angular/forms";

//Models
import { Report } from "src/app/models/Report";
import { Service } from "src/app/models/Service";
import { PaginationParams } from "src/app/models/global.request";

//Utils
import Formatter  from "../../../../utils/formatters";

//Services
import { ListsService } from "src/app/services/lists/lists.service";
import { AdminRequestsService } from "src/app/services/admin-requests/admin-requests.service";

//States
import { AuthState } from '../../../../stores/auth/authentication.reducer';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { User } from "src/app/models/User";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Person } from "src/app/models/Person";
import * as saveAs from "file-saver";
import { Company } from "src/app/models/Company";

@Component({
  selector: "app-dash-home",
  templateUrl: "./dash-home.component.html",
  styleUrls: ["./dash-home.component.scss"],

})
export class DashboardHomeComponent {
  ROUTES = ROUTES;
  formatter!:Formatter;
  authState$: Observable<AuthState>;

  start = 0;
  limit = 10;
  showLoadMore = false;

  targets: Report[] = [];

  public utils = Formatter;

  orderby = '';
  orderdirDesc: boolean | null = null;
  filters: any[] = [];

  filtersVisibility = {
    id: false,
    customer: false,
    target: false,
    service_id: false,
    priority: false,
    status: false,
    operator_id: false,
  };

  filtersForm = this.fb.group({
    id: [""],
    customer: [""],
    target: [""],
    service_id: [""],
    priority: [""],
    status: [""],
    operator_id: [""],
  });

  services: Service[] = [];
  operators: User[] = [];

  //Constructor
  constructor(
    public fb: FormBuilder,
    public toastr: ToastrService,
    public translate: TranslateService,
    public listsService: ListsService,
    public adminRequestsService: AdminRequestsService,
    private store: Store<{ authState: AuthState }>
  ) {
    this.formatter = new Formatter();
    this.authState$ = store.select('authState');
    this.listsService.getServices().subscribe(res => {
      if (res.result && res.data) {
        this.services = this.formatter.formatResponseGetServices(res.data);
      }
    });

    this.listsService.getOperators().subscribe(res => {
      if (res.result && res.data) {
        this.operators = this.formatter.formatResponseGetOperators(res.data);
      }
    })

    const params = new PaginationParams(this.start, this.limit);
    this.adminRequestsService.getRequestsList(params).subscribe(res => {
      if (res.result) {
        this.targets = this.formatter.formatResponseGetAdminRequests(res.data);

        if (this.targets.length < res.data.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  // On Component Initialized
  ngOnInit(): void {

  }

  openFilter(column: string) {
    switch (column) {
      case 'id':
        this.filtersVisibility.id = true;
        break;
      case 'customer':
        this.filtersVisibility.customer = true;
        break;
      case 'target':
        this.filtersVisibility.target = true;
        break;
      case 'service_id':
        this.filtersVisibility.service_id = true;
        break;
      case 'priority':
        this.filtersVisibility.priority = true;
        break;
      case 'status':
        this.filtersVisibility.status = true;
        break;
      case 'operator_id':
        this.filtersVisibility.operator_id = true;
        break;
      default:
        break;
    }
  }

  orderBy(column: string) {
    this.orderby = column;
    this.orderdirDesc = this.orderdirDesc === null ? false : !this.orderdirDesc;
    const params = new PaginationParams(0, this.limit, this.orderby, this.orderdirDesc, this.filters);
    this.adminRequestsService.getRequestsList(params).subscribe(res => {
      if (res.result) {
        this.targets = this.formatter.formatResponseGetAdminRequests(res.data);

        if (this.targets.length < res.data.total) {
          this.showLoadMore = true;
          this.start = this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  onChangeSavePriority(propertyValue: string, target: Report, target_index: number) {
    this.adminRequestsService.setProperty(target.id, 'Priority', { priority: propertyValue }).subscribe(res => {
      if (res.result && res.data) {
        this.targets[target_index] = this.formatter.formatResponseGetAdminRequest(res.data);
        this.toastr.success(this.translate.instant('updatePrioritySuccess'));
      }
    });
  }
  onChangeSaveStatus(propertyValue: string, target: Report, target_index: number) {
    this.adminRequestsService.setProperty(target.id, 'Status', { status: propertyValue }).subscribe(res => {
      if (res.result && res.data) {
        this.targets[target_index] = this.formatter.formatResponseGetAdminRequest(res.data);
        this.toastr.success(this.translate.instant('updateStatusSuccess'));
      }
    });
  }
  onChangeSaveHolder(propertyValue: string, target: Report, target_index: number) {
    this.adminRequestsService.setProperty(target.id, 'Operator', { operator_id: parseInt(propertyValue) }).subscribe(res => {
      if (res.result && res.data) {
        this.targets[target_index] = this.formatter.formatResponseGetAdminRequest(res.data);
        this.toastr.success(this.translate.instant('updateHolderSuccess'));
      }
    });
  }
  onChangeSaveDeadline(propertyValue: string, target: Report, target_index: number) {
    this.adminRequestsService.setProperty(target.id, 'ExpiryDate', { expiry_date: propertyValue }).subscribe(res => {
      if (res.result && res.data) {
        this.targets[target_index] = this.formatter.formatResponseGetAdminRequest(res.data);
        this.toastr.success(this.translate.instant('updateDeadlineSuccess'));
      }
    });
  }

  onSubmitFilterList() {
    this.filters = [];
    Object.keys(this.filtersForm.controls).forEach(key => {
      const control = this.filtersForm.get(key) as FormGroup;
      if (control.value) {
        this.filters.push({key: key, value: control.value});
      }
    });
    const params = new PaginationParams(0, this.limit, this.orderby, this.orderdirDesc, this.filters);
    this.adminRequestsService.getRequestsList(params).subscribe(res => {
      if (res.result) {
        this.targets = this.formatter.formatResponseGetAdminRequests(res.data);

        if (this.targets.length < res.data.total) {
          this.showLoadMore = true;
          this.start = this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  onClickResetFilter(key: string) {
    this.filtersForm.patchValue({
      [key]: '',
    });
    this.onSubmitFilterList();
  }

  onClickLoadMore() {
    const params = new PaginationParams(this.start, this.limit, this.orderby, this.orderdirDesc, this.filters);
    this.adminRequestsService.getRequestsList(params).subscribe(res => {
      if (res.result) {
        this.targets = this.targets.concat(this.formatter.formatResponseGetAdminRequests(res.data));

        if (this.targets.length < res.data.total) {
          this.showLoadMore = true;
          this.start += this.limit;
        } else {
          this.showLoadMore = false;
        }
      }
    })
  }

  downloadReportCompany(report: Report, target: Company) {
    this.adminRequestsService.generateReport(report.id, 'company', +target.id!).subscribe(res => {
      if (res.headers.get('Content-Type')?.includes('application/pdf')) {
        var fileName = this.getDateForReportDownload(report.created_at) + ' ' + target.business_name;
        const file = new Blob([res.body], {type: 'application/pdf'});
        saveAs(file, fileName);
      }
    });
  }

  downloadReportPerson(report: Report, target: Person) {
    this.adminRequestsService.generateReport(report.id, 'person', +target.id!).subscribe(res => {
      if (res.headers.get('Content-Type')?.includes('application/pdf')) {
        var fileName = this.getDateForReportDownload(report.created_at) + ' ' + target.name + ' ' + target.surname;
        const file = new Blob([res.body], {type: 'application/pdf'});
        saveAs(file, fileName);
      }
    });
  }

  getTodayDate() {
    return new Date().toISOString().split('T')[0];
  }

  // returns date as YYYY-MM-DD
  getDateForInput(date: string): string {
    return date.split('T')[0];
  }

  // returns date as YYYY/MM/DD
  getDateForDisplay(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '/');
    else
      return '';
  }

  // returns date as YYYY_MM_DD
  getDateForReportDownload(date: string | null): string {
    if (date)
      return date.split('T')[0].replaceAll('-', '_');
    else
      return '';
  }
}

