import { createReducer, on } from "@ngrx/store";
import { credits } from "./credits.actions";

export const initialState: CreditsState = {
  available: 0
};

export interface CreditsState {
  available: number;
}
export const CreditsReducer = createReducer(
  initialState,
  on(credits, (state, { available }) => ({
    ...state,
    available,
  }))
)
