import { Person } from './Person';
import { Company } from './Company';

export class PaginationParams {
  public start: number;
  public limit: number;
  public orderby: string;
  public orderdir: string;
  public filters: any[];

  constructor(start: number, limit: number, orderby: string = '', isDesc: boolean | null = null, filters: any[] = []) {
    this.start = start;
    this.limit = limit;
    this.orderby = orderby;
    this.orderdir = isDesc === null ? '' : (isDesc ? 'desc' : 'asc');
    this.filters = filters;
  }
}

export class CreateUserRequestRequest {
  public service_ids: (number | undefined)[];
  public persons: Person[];
  public company: Company | null;

  constructor(service_ids: (number | undefined)[], persons: Person[], company: Company | null = null) {
    this.service_ids = service_ids;
    this.persons = persons;
    this.company = company;
  }
}

export class SummaryRequest {
  public request_target_type: string;
  public request_target_id: number;
  public summary: string;
  public summary_links: string;
  public summary_keywords: string;

  constructor (request_target_type: string, request_target_id: number, summary: string, summary_links: string, summary_keywords: string) {
    this.request_target_type = request_target_type;
    this.request_target_id = +request_target_id;
    this.summary = summary;
    this.summary_links = summary_links;
    this.summary_keywords = summary_keywords;
  }
}

export class CategoryStatusRequest {
  public request_target_type: string;
  public request_target_id: number;
  public research_category_id: number;
  public severity: string;

  constructor(request_target_type: string, request_target_id: number, research_category_id: number, severity: string) {
    this.request_target_type = request_target_type;
    this.request_target_id = +request_target_id;
    this.research_category_id = research_category_id;
    this.severity = severity;
  }
}
